import React, { useEffect, useState } from 'react';
import { Button, Form, Header, Message, Segment, Icon } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, copy, showError, showSuccess, timestamp2string } from '../../helpers';
import { renderQuotaWithPrompt } from '../../helpers/render';
import { getUserLanguage, translations } from '../../utils/language';

const EditToken = () => {
  const params = useParams();
  const tokenId = params.id;
  const isEdit = tokenId !== undefined;
  const [loading, setLoading] = useState(isEdit);
  const [modelOptions, setModelOptions] = useState([]);
  const currentLanguage = getUserLanguage();
  const t = translations[currentLanguage].token.edit;

  const originInputs = {
    name: '',
    remain_quota: isEdit ? 0 : 500000,
    expired_time: -1,
    unlimited_quota: false,
    models: [],
    subnet: "",
  };
  const [inputs, setInputs] = useState(originInputs);
  const { name, remain_quota, expired_time, unlimited_quota } = inputs;
  const navigate = useNavigate();
  const handleInputChange = (e, { name, value }) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const handleCancel = () => {
    navigate('/token');
  };
  const setExpiredTime = (month, day, hour, minute) => {
    let now = new Date();
    let timestamp = now.getTime() / 1000;
    let seconds = month * 30 * 24 * 60 * 60;
    seconds += day * 24 * 60 * 60;
    seconds += hour * 60 * 60;
    seconds += minute * 60;
    if (seconds !== 0) {
      timestamp += seconds;
      setInputs({ ...inputs, expired_time: timestamp2string(timestamp) });
    } else {
      setInputs({ ...inputs, expired_time: -1 });
    }
  };

  const setUnlimitedQuota = () => {
    setInputs({ ...inputs, unlimited_quota: !unlimited_quota });
  };

  const loadToken = async () => {
    let res = await API.get(`/api/token/${tokenId}`);
    const { success, message, data } = res.data;
    if (success) {
      if (data.expired_time !== -1) {
        data.expired_time = timestamp2string(data.expired_time);
      }
      if (data.models === '') {
        data.models = [];
      } else {
        data.models = data.models.split(',');
      }
      setInputs(data);
    } else {
      showError(message);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (isEdit) {
      loadToken().then();
    }
    loadAvailableModels().then();
  }, []);

  const loadAvailableModels = async () => {
    let res = await API.get(`/api/user/available_models`);
    const { success, message, data } = res.data;
    if (success) {
      let options = data.map((model) => {
        return {
          key: model,
          text: model,
          value: model
        };
      });
      setModelOptions(options);
    } else {
      showError(message);
    }
  };

  const submit = async () => {
    if (!isEdit && inputs.name === '') return;
    let localInputs = inputs;
    localInputs.remain_quota = parseInt(localInputs.remain_quota);
    if (localInputs.expired_time !== -1) {
      let time = Date.parse(localInputs.expired_time);
      if (isNaN(time)) {
        showError(t.messages.invalidExpireTime);
        return;
      }
      localInputs.expired_time = Math.ceil(time / 1000);
    }
    localInputs.models = localInputs.models.join(',');
    let res;
    if (isEdit) {
      res = await API.put(`/api/token/`, { ...localInputs, id: parseInt(tokenId) });
    } else {
      res = await API.post(`/api/token/`, localInputs);
    }
    const { success, message } = res.data;
    if (success) {
      if (isEdit) {
        showSuccess(t.messages.updateSuccess);
      } else {
        showSuccess(t.messages.createSuccess);
        setInputs(originInputs);
      }
    } else {
      showError(message);
    }
  };

  const segmentStyle = {
    background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%)',
    borderRadius: '15px',
    padding: '2em',
    boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
    marginTop: '2em'
  };

  const formSegmentStyle = {
    background: 'white',
    borderRadius: '10px',
    padding: '1.5em',
    marginBottom: '1em',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
  };

  const buttonStyle = {
    borderRadius: '8px',
    transition: 'all 0.3s ease',
    margin: '0.2em'
  };

  return (
    <>
      <Segment style={formSegmentStyle}>
        <Header as='h2'>
          <Icon name={isEdit ? 'edit' : 'plus'} />
          <Header.Content>
            {isEdit ? t.updateTitle : t.createTitle}
            <Header.Subheader>
              {isEdit ? t.updateSubtitle : t.createSubtitle}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>

      <Segment style={segmentStyle} loading={loading}>
        <Form autoComplete='new-password'>
          <Form.Field>
            <label>{t.name}</label>
            <Form.Input
              icon='tag'
              iconPosition='left'
              name='name'
              placeholder={t.namePlaceholder}
              onChange={handleInputChange}
              value={name}
              autoComplete='new-password'
              required={!isEdit}
            />
          </Form.Field>

          <Form.Field>
            <label>{t.models}</label>
            <Form.Dropdown
              placeholder={t.modelsPlaceholder}
              name='models'
              fluid
              multiple
              search
              onLabelClick={(e, { value }) => {
                copy(value).then();
              }}
              selection
              onChange={handleInputChange}
              value={inputs.models}
              autoComplete='new-password'
              options={modelOptions}
            />
          </Form.Field>

          <Form.Field>
            <label>{t.ipLimit}</label>
            <Form.Input
              icon='shield'
              iconPosition='left'
              name='subnet'
              placeholder={t.ipLimitPlaceholder}
              onChange={handleInputChange}
              value={inputs.subnet}
              autoComplete='new-password'
            />
          </Form.Field>

          <Form.Field>
            <label>{t.expireTime}</label>
            <Form.Input
              icon='calendar'
              iconPosition='left'
              name='expired_time'
              placeholder={t.expireTimePlaceholder}
              onChange={handleInputChange}
              value={expired_time}
              autoComplete='new-password'
              type='datetime-local'
            />
          </Form.Field>

          <div style={{ marginBottom: '1em' }}>
            <Button.Group size='small'>
              <Button style={buttonStyle} onClick={() => setExpiredTime(0, 0, 0, 0)}>
                <Icon name='infinity' />
                {t.expireButtons.never}
              </Button>
              <Button style={buttonStyle} onClick={() => setExpiredTime(1, 0, 0, 0)}>
                <Icon name='calendar alternate' />
                {t.expireButtons.oneMonth}
              </Button>
              <Button style={buttonStyle} onClick={() => setExpiredTime(0, 1, 0, 0)}>
                <Icon name='clock' />
                {t.expireButtons.oneDay}
              </Button>
              <Button style={buttonStyle} onClick={() => setExpiredTime(0, 0, 1, 0)}>
                <Icon name='hourglass half' />
                {t.expireButtons.oneHour}
              </Button>
              <Button style={buttonStyle} onClick={() => setExpiredTime(0, 0, 0, 1)}>
                <Icon name='stopwatch' />
                {t.expireButtons.oneMinute}
              </Button>
            </Button.Group>
          </div>

          <Message info icon>
            <Icon name='info circle' />
            <Message.Content>
              <Message.Header>{t.quotaInfo.title}</Message.Header>
              <p>{t.quotaInfo.content}</p>
            </Message.Content>
          </Message>

          <Form.Field>
            <label>{`${t.quota}${renderQuotaWithPrompt(remain_quota)}`}</label>
            <Form.Input
              icon='chart bar'
              iconPosition='left'
              name='remain_quota'
              placeholder={t.quotaPlaceholder}
              onChange={handleInputChange}
              value={remain_quota}
              autoComplete='new-password'
              type='number'
              disabled={unlimited_quota}
            />
          </Form.Field>

          <Button 
            style={buttonStyle} 
            onClick={setUnlimitedQuota}
            color={unlimited_quota ? 'orange' : 'blue'}
          >
            <Icon name={unlimited_quota ? 'lock open' : 'lock'} />
            {unlimited_quota ? t.unlimitedQuota.disable : t.unlimitedQuota.enable}
          </Button>

          <div style={{ marginTop: '2em', textAlign: 'right' }}>
            <Button style={buttonStyle} onClick={handleCancel}>
              <Icon name='cancel' />
              {t.buttons.cancel}
            </Button>
            <Button style={buttonStyle} positive onClick={submit}>
              <Icon name='check' />
              {t.buttons.submit}
            </Button>
          </div>
        </Form>
      </Segment>
    </>
  );
};

export default EditToken;
