// 獲取使用者的語言設定
export function getUserLanguage() {
  // 優先使用用戶設定的語言
  const savedLang = localStorage.getItem('user_language');
  if (savedLang) {
    return savedLang;
  }

  // 獲取瀏覽器的語言設定列表
  const browserLangs = navigator.languages || [navigator.language || navigator.userLanguage];
  
  // 支援的語言列表
  const supportedLangs = ['zh-TW', 'en'];
  
  // 遍歷瀏覽器語言列表，尋找第一個匹配的支援語言
  for (let lang of browserLangs) {
    lang = lang.toLowerCase();
    
    // 處理中文語系
    if (lang.startsWith('zh')) {
      // 優先使用繁體中文
      if (lang.includes('tw') || lang.includes('hk') || lang.includes('hant')) {
        return 'zh-TW';
      }
      // 其他中文語系也預設使用繁體中文
      return 'zh-TW';
    }
  }

  // 預設使用英文
  return 'en';
}

// 設定使用者語言
export function setUserLanguage(lang) {
  localStorage.setItem('user_language', lang);
  document.documentElement.lang = lang;
}

// 初始化語言設定
export function initLanguage() {
  const lang = getUserLanguage();
  setUserLanguage(lang);
  return lang;
}

// 語言內容對照表
export const translations = {
  'zh-TW': {
    mainTitle: 'We Make AI More Accessible',
    subTitle: '我們為您提供強大的 API 解決方案',
    description: '整合 100+ 種頂尖 AI 模型，提供對話與 API 服務',
    learnMore: '暸解更多',
    features: {
      oneClick: '快速接入',
      payAsYouGo: '按需付費',
      unlimited: '無限使用',
      official: '官方管道'
    },
    featureDescs: {
      oneClick: '市面上所有 AI 模型',
      payAsYouGo: '告別固定及多平台月費，應對所有需求',
      unlimited: '不受流量限制，隨時隨地使用',
      official: '確保資料安全'
    },
    sectionTitles: {
      oneStima: '一個 Stima API，無限可能',
      sectionDesc: '我們提供『優惠』匯率兌換額度，節省超過50%費用，註冊即享 3 美金平台額度。',
      register: '立即註冊',
      modelList: '模型列表',
      features: '功能特色',
      pricing: '價格方案',
      contact: '聯絡我們'
    },
    partners: {
      stimaChat: 'StimaChat：先進的對話系統',
      immersive: '沉浸式翻譯：實時多語言翻譯',
      chatbox: 'Chatbox AI：全平台智慧對話助理',
      cursor: 'Cursor IDE：智慧化開發環境',
      continue: 'Continue: AI 開發助手',
      claude: 'Cline (prev. Claude Dev): AI 開發助手',
      aider: 'Aider: AI 開發助手'
    },
    comparison: {
      betterChoice: '現在有更好的選擇！',
      forEngineers: '如果您是經常使用 AI 輔助開發的工程師，',
      valueProposition: '我們以同樣價格提供更高的使用價值，滿足您的開發需求。',
      compareItems: '比較項目',
      priceComparison: '$20 美金可獲得',
      cursorUsage: '500 次之高速訪問（每月重置）',
      stimaUsage: '約4400 次之高速訪問，額度用完為止',
      paymentModel: '付費模式',
      cursorPayment: '固定月費，用不完仍需付費',
      stimaPayment: 'Pay As You Go，用多少付多少',
      highDemand: '高用量需求用戶',
      cursorLimit: '超過 500 次後降速',
      stimaLimit: '提供高速訪問，無使用次數限制',
      throughputComparison: '同樣價格，Stima 提供高達 8.8 倍程式碼吞吐量。',
      testNote: '以 Anthropic Claude 3.5 Sonnet 20240620 模型計費，每次吞吐約300行程式碼之內部測試，僅供參考。'
    },
    advantages: {
      title: '為什麼選擇 Stima API ?',
      subtitle: '探索 AI 的無限可能，體驗卓越服務',
      items: {
        pricing: {
          title: '優惠定價',
          desc: '相較於官方管道，我們提供更實惠的 API 價格'
        },
        noFees: {
          title: '無月費負擔',
          desc: '告別每月 $USD 20 訂閱費，採用按需付費制'
        },
        credit: {
          title: '註冊優惠',
          desc: '註冊即贈送 $3 平台額度，立即開始體驗'
        },
        models: {
          title: '全模型支援',
          desc: '支援市面上所有主流 AI 模型，一次整合全部所需'
        },
        tools: {
          title: '先進工具',
          desc: '透過 StimaChat 和 Stima Playground 探索網路搜尋功能'
        },
        performance: {
          title: '效能提升',
          desc: '與 Cursor 整合可實現高達 8.8 倍的程式碼生成效率'
        },
        scalability: {
          title: '高頻請求',
          desc: '輕鬆處理大量 API 呼叫，無使用限制'
        },
        documentation: {
          title: '完整文件',
          desc: '提供完整的 API 文件，加速開發流程'
        },
        experts: {
          title: '專家角色',
          desc: 'StimaChat 和 Playground 提供多種專家角色，專注解決專業問題'
        }
      }
    },
    stimaChat: {
      title: 'StimaChat - 智慧對話的未來',
      features: {
        naturalConversation: {
          title: '自然流暢對話',
          desc: '自動識別話題轉換，開啟新對話，避免記憶干擾',
          whyChoose: '為什麼選擇我們？',
          benefits: {
            security: {
              title: '安全可靠',
              desc: '資料加密傳輸，確保您的隱私安全'
            },
            speed: {
              title: '快速響應',
              desc: '優化的服務架構，提供即時對話體驗'
            },
            developer: {
              title: '開發者友好',
              desc: '完整的 API 文件和開發工具支援'
            },
            pricing: {
              title: '優惠定價',
              desc: '按量計費，無需訂閱，更具性價比'
            }
          },
          newFeature: {
            title: '全新功能上線！',
            desc: '支援程式碼生成與網路搜尋，開發更高效'
          },
          tryNow: '立即體驗'
        },
        deepUnderstanding: {
          title: '深度理解需求',
          desc: '透過對話紀錄，從個人化角度精準把握您的想法'
        },
        smartPrompt: {
          title: '智慧提示詞生成',
          desc: 'Auto Prompt 系統助您優化提示，讓 LLM 產出理想結果'
        },
        flexibleGoal: {
          title: '確保資料安全',
          desc: '聊天紀錄僅儲存於用戶端，確保您的隱私安全'
        }
      },
      whyChoose: '為什麼選擇 StimaChat？',
      benefits: {
        security: {
          title: '安全可靠',
          desc: '資料加密傳輸，確保您的隱私安全'
        },
        speed: {
          title: '快速響應',
          desc: '優化的服務架構，提供即時對話體驗'
        },
        developer: {
          title: '開發者友好',
          desc: '完整的 API 文件和開發工具支援'
        },
        pricing: {
          title: '優惠定價',
          desc: '按量計費，無需訂閱，更具性價比'
        }
      },
      newFeature: {
        title: '推出新功能！',
        desc: '現在支援程式碼生成和連網搜尋，讓軟體開發更高效。'
      },
      tryNow: '立即體驗'
    },
    stimaFeatures: {
      title: '突破性的 AI 對話體驗',
      subtitle: '重新定義 AI 互動方式',
      features: {
        userInterface: {
          title: '極致用戶體驗',
          desc: '精心設計的介面，回應更直觀，操作更流暢'
        },
        codeSupport: {
          title: '全方位開發支援',
          desc: '支援程式碼生成、LaTex 數學公式，開發體驗更完整'
        },
        webSearch: {
          title: '智慧搜尋整合',
          desc: '支援所有 AI 模型聯網搜尋，資訊更新更即時'
        },
        smartCompression: {
          title: '長對話優化',
          desc: '智慧壓縮技術，維持對話連貫性的同時優化性能'
        },
        aiRoles: {
          title: '專業角色定制',
          desc: '多種 AI 專家角色，讓對話更專業、更聚焦'
        },
        conversation: {
          title: '智慧對話管理',
          desc: '自動分類與組織對話，尋找歷史記錄更便捷'
        },
        naturalConversation: {
          title: '自然對話體驗',
          desc: '智慧識別對話主題，自動管理上下文',
          whyChoose: '為什麼選擇我們？',
          benefits: {
            security: {
              title: '安全可靠',
              desc: '資料加密傳輸，確保您的隱私安全'
            },
            speed: {
              title: '快速響應',
              desc: '優化的服務架構，提供即時對話體驗'
            },
            developer: {
              title: '開發者友好',
              desc: '完整的 API 文件和開發工具支援'
            },
            pricing: {
              title: '優惠定價',
              desc: '按量計費，無需訂閱，更具性價比'
            }
          },
          newFeature: {
            title: '全新功能上線！',
            desc: '支援程式碼生成與網路搜尋，開發更高效'
          },
          tryNow: '立即體驗'
        }
      }
    },
    // English translations
    stimaFeatures: {
      title: 'Revolutionary AI Conversation Experience',
      subtitle: 'Redefining AI Interaction',
      features: {
        userInterface: {
          title: 'Superior User Experience',
          desc: 'Intuitive interface with smoother interactions and responses'
        },
        codeSupport: {
          title: 'Comprehensive Development Support',
          desc: 'Code generation and LaTex rendering for complete development experience'
        },
        webSearch: {
          title: 'Smart Search Integration',
          desc: 'Web search capability across all AI models for real-time information'
        },
        smartCompression: {
          title: 'Long Conversation Optimization',
          desc: 'Smart compression while maintaining conversation coherence'
        },
        aiRoles: {
          title: 'Professional Role Customization',
          desc: 'Multiple AI expert roles for focused, professional conversations'
        },
        conversation: {
          title: 'Intelligent Conversation Management',
          desc: 'Auto-categorization for easier history navigation'
        },
        naturalConversation: {
          title: 'Natural Conversation Experience',
          desc: 'Smart topic identification and context management',
          whyChoose: 'Why Choose Us?',
          benefits: {
            security: {
              title: 'Secure & Reliable',
              desc: 'Encrypted data transmission ensures your privacy'
            },
            speed: {
              title: 'Fast Response',
              desc: 'Optimized architecture for real-time conversation'
            },
            developer: {
              title: 'Developer Friendly',
              desc: 'Complete API documentation and development tools'
            },
            pricing: {
              title: 'Better Pricing',
              desc: 'Pay-per-use, no subscription, better value'
            }
          },
          newFeature: {
            title: 'New Features Released!',
            desc: 'Now with code generation and web search capabilities'
          },
          tryNow: 'Try Now'
        }
      }
    },
    pricing: {
      title: '模型價格與計費單位'
    },
    statistics: {
      topAd: {
        title: '有超過',
        number: '1000+',
        description: '用戶選擇使用我們的服務'
      },
      bottomStats: {
        availability: {
          number: '99.9%',
          label: '服務可用性'
        },
        models: {
          number: '100+',
          label: 'AI 模型支援'
        }
      }
    },
    exchangeRate: {
      title: '超值匯率優惠',
      description: {
        line1: '遠低於美金匯率的價格',
        line2: '換取平台額度！'
      },
      rate: {
        from: '15 TWD',
        to: '1 USD (平台額度)'
      }
    },
    search: {
      placeholder: '搜尋模型名稱、發行商或模型呼叫別名...'
    },
    filter: {
      all: '全部',
      morePublishers: '更多發行商'
    },
    table: {
      headers: {
        modelName: '模型名稱',
        publisher: '發行商',
        billingUnit: '計費單位',
        price: '價格 (美金/K tokens)',
        modelAlias: '模型呼叫別名'
      },
      noResults: {
        header: '沒有找到符合輸入結果的模型資訊',
        content: '請嘗試其他關鍵字搜尋或清除搜尋條件'
      },
      copied: '已複製!',
      clickToCopy: '點擊複製'
    },
    billing: {
      payPerUse: '按量付費',
      payPerRequest: '按次付費',
      free: '免費'
    },
    footer: {
      description: '我們致力讓 AI 技術更易於使用，提供創新的 AI 解決方案。',
      services: {
        title: '服務',
        items: {
          apiGuide: 'Stima API 使用指南',
          chat: 'StimaChat 對話系統',
          research: 'Stima Research 研究報告',
          playground: 'Stima Playground',
          status: 'Stima 伺服器狀態',
          cursorHistory: 'Cursor 歷史版本安裝檔'
        }
      },
      contact: {
        title: '聯繫我們'
      }
    },
    navigation: {
      guide: '使用指南',
      chat: '聊天介面',
      channel: '管道',
      purchase: '購買',
      apiKey: 'Keys',
      modelList: '模型列表',
      redemption: '兌換管理',
      topup: '額度管理',
      user: '使用者',
      log: '紀錄',
      settings: '設定'
    },
    auth: {
      login: '登入',
      logout: '登出',
      register: '註冊'
    },
    payment: {
      title: 'Stima API 購買方案',
      subtitle: '為您提供強大、靈活的 API 服務',
      viewPlans: '立即選擇方案',
      table: {
        headers: {
          planName: '方案名稱',
          apiCredit: 'API 額度',
          price: '價格 (TWD) (未含稅)',
          select: '選擇購買方案'
        },
        plans: {
          starter: {
            name: '入門方案',
            price: 'TWD 75'
          },
          basic: {
            name: '一般方案',
            price: 'TWD 120'
          },
          standard: {
            name: '小資方案',
            price: 'TWD 240'
          },
          premium: {
            name: '超值方案',
            price: 'TWD 375'
          }
        },
        select: '選擇'
      },
      customPlan: {
        text: '如需客製化方案或更高額度，請聯繫我們的企業解決方案團隊：',
        email: '電子郵件'
      },
      policy: {
        title: '服務政策',
        description: '您可以在此查看我們的完整政策內容',
        refund: '退款政策',
        privacy: '隱私政策',
        terms: '使用條款'
      },
      selectedPlan: {
        title: '您選擇的方案',
        name: '方案名稱：',
        price: '價格：',
        priceUnit: 'TWD (未含稅)',
        emailPrompt: '請於下方欄位輸入您的電子郵件地址以便收取加值碼'
      },
      orderForm: {
        title: '訂購資訊',
        email: {
          label: '電子郵件地址：',
          placeholder: '請輸入您的電子郵件地址以便收取加值碼'
        },
        agreement: {
          text: '我已閱讀並同意 Stima AI'
        },
        submit: '進入付款頁面'
      },
      security: '您的支付資訊將被加密並安全傳輸',
      announcement: {
        message: '系統公告：部分郵件服務（如 Outlook, Hotmail, Yahoo 等）可能無法收到系統郵件，包括加值碼和客服郵件。請改用其他郵件服務。'
      },
      nav: {
        home: '首頁',
        contact: '聯絡我們'
      },
      email: {
        redemption: {
          subject: 'Stima API {planName}加值碼',
          title: '感謝您購買{planName}！',
          code: '您的加值碼是：',
          instruction: '請前往 Stima API 加值區兌換您的額度。',
          warning: '請注意，加值碼僅供一次使用，請妥善保管。',
          support: '如有任何問題，請點擊下方按鈕聯繫我們的客服團隊。',
          contactButton: '聯繫客服'
        }
      }
    },
    token: {
      title: '管理 API Key',
      edit: {
        updateTitle: '更新 API Key 資訊',
        createTitle: '建立新的 API Key',
        updateSubtitle: '修改現有 API Key 的設置',
        createSubtitle: '建立一個新的 API Key 並設置其權限',
        name: '名稱',
        namePlaceholder: '請輸入名稱',
        models: '模型範圍',
        modelsPlaceholder: '請選擇允許使用的模型，留空則不進行限制',
        ipLimit: 'IP限制',
        ipLimitPlaceholder: '請輸入允許存取的IP，例如：192.168.0.0/24，請使用英文逗號分隔多個IP。如不限制請留空。',
        expireTime: '過期時間',
        expireTimePlaceholder: '請輸入過期時，格式為 yyyy-MM-dd HH:mm:ss，-1 表示無限制',
        expireButtons: {
          never: '永不過期',
          oneMonth: '一個月後過期',
          oneDay: '一天後過期',
          oneHour: '一小時後過期',
          oneMinute: '一分鐘後過期'
        },
        quotaInfo: {
          title: '額度使用說明',
          content: 'API Key 的額度僅用於限制 API Key 的最大額度使用量，實際使用將受限於帳號的剩餘額度。'
        },
        quota: '額度',
        quotaPlaceholder: '請輸入額度',
        unlimitedQuota: {
          enable: '設為無限額度',
          disable: '取消無限額度'
        },
        buttons: {
          cancel: '取消',
          submit: '送出'
        },
        messages: {
          updateSuccess: 'API Key 更新成功！',
          createSuccess: 'API Key 建立成功，請在列表頁面點擊複製獲取 API Key！',
          invalidExpireTime: '過期時間格式錯誤！'
        }
      }
    },
    topup: {
      title: '額度管理中心',
      subtitle: '管理您的 API 使用額度，隨時補充以確保服務不中斷',
      quotaManagement: {
        title: '額度加值',
        subtitle: '輸入加值碼或前往購買額度頁面',
        redemptionCode: {
          label: '加值碼',
          placeholder: '請輸入加值碼',
          invalidCode: '請輸入有效的加值碼',
          requestFailed: '加值請求失敗，請稍後再試'
        },
        success: {
          title: '加值成功！',
          message: '您的額度已經更新'
        },
        buttons: {
          purchase: '購買額度',
          redeem: '使用加值碼',
          processing: '處理中...'
        },
        popup: {
          hint: '請先輸入加值碼'
        },
        errors: {
          noTopUpLink: '系統管理員未設定加值連結！'
        }
      },
      currentQuota: {
        title: '當前額度',
        available: '可用額度'
      },
      features: {
        security: '安全可靠的額度管理',
        instant: '即時入帳，立即可用',
        service: '24/7 全天候服務'
      },
      logs: {
        title: '使用明細',
        totalQuota: '總花費額度',
        clickToView: '點擊查看',
        fields: {
          apiKeyName: 'API Key 名稱',
          modelName: '模型名稱',
          startTime: '開始時間',
          endTime: '結束時間',
          channelId: '管道 ID',
          username: '用戶名稱',
          optional: '可選值'
        },
        query: '查詢',
        refresh: '重新整理',
        types: {
          all: '全部',
          topup: '加值',
          consume: '消費',
          manage: '管理',
          system: '系統',
          unknown: '未知'
        },
        tableHeaders: {
          time: '時間',
          channel: '管道',
          user: '用戶',
          apiKey: 'API Key',
          type: '類型',
          model: '模型',
          inputTokens: '輸入Token數',
          outputTokens: '輸出Token數',
          quota: '消耗額度',
          details: '詳情',
          actions: '操作'
        },
        filterType: '選擇明細分類',
        pagination: {
          jumpTo: '跳至',
          page: '頁',
          total: '共'
        }
      }
    },
    logs: {
      title: '使用明細',
      totalQuota: '總花費額度',
      clickToView: '點擊查看',
      fields: {
        apiKeyName: 'API Key 名稱',
        modelName: '模型名稱',
        startTime: '開始時間',
        endTime: '結束時間',
        channelId: '管道 ID',
        username: '用戶名稱',
        optional: '可選值'
      },
      query: '查詢',
      refresh: '重新整理',
      types: {
        all: '全部',
        topup: '加值',
        consume: '消費',
        manage: '管理',
        system: '系統',
        unknown: '未知'
      },
      tableHeaders: {
        time: '時間',
        channel: '管道',
        user: '用戶',
        apiKey: 'API Key',
        type: '類型',
        model: '模型',
        inputTokens: '輸入Token數',
        outputTokens: '輸出Token數',
        quota: '消耗額度',
        details: '詳情',
        actions: '操作'
      },
      filterType: '選擇明細分類',
      pagination: {
        jumpTo: '跳至',
        page: '頁',
        total: '共'
      }
    },
    login: {
      title: '用戶登入',
      fields: {
        username: {
          placeholder: '使用者名稱 / 電子郵件地址'
        },
        password: {
          placeholder: '密碼',
          show: '顯示密碼',
          hide: '隱藏密碼'
        },
        rememberMe: '記住我'
      },
      buttons: {
        login: '登入',
        forgotPassword: '忘記密碼',
        register: '申請帳號'
      },
      thirdParty: {
        title: '第三方登入',
        wechat: {
          title: '微信登入',
          scanQRCode: '掃描二維碼關注公眾號，並輸入「驗證碼」獲取登入碼（三分鐘內有效）',
          verificationCode: '輸入驗證碼',
          confirm: '確認登入'
        }
      },
      messages: {
        loginExpired: '未登入或登入已過期，請重新登入！',
        loginSuccess: '登入成功！',
        changeDefaultPassword: '請立刻修改預設密碼！'
      }
    },
    passwordReset: {
      title: '密碼重設確認',
      fields: {
        email: {
          placeholder: '電子郵件地址'
        },
        newPassword: {
          placeholder: '新密碼'
        }
      },
      buttons: {
        submit: '送出',
        completed: '密碼重設完成'
      },
      messages: {
        passwordCopied: '新密碼已複製到剪貼板：',
        copySuccess: '密碼已複製到剪貼板：'
      }
    },
    passwordResetForm: {
      title: '重設密碼',
      description: '請輸入您註冊時使用的電子信箱，我們將發送重設密碼的連結給您。',
      fields: {
        email: {
          placeholder: '請輸入您先前註冊的電子信箱',
          error: '請輸入有效的電子信箱地址'
        }
      },
      buttons: {
        submit: '發送重設連結',
        retry: '重試 ({countdown}) 秒'
      },
      messages: {
        turnstileWait: '請稍候幾秒重試，Turnstile 正在檢查使用者環境！',
        resetEmailSent: '重設郵件已發送，請檢查您的電子信箱！'
      }
    },
    personalSetting: {
      title: '通用設定',
      buttons: {
        updateProfile: '更新個人資訊（更改帳號及密碼資訊）',
        generateToken: '生成系統訪問 Secret',
        copyInviteLink: '複製邀請連結 （目前尚未開放）',
        deleteAccount: '刪除個人帳號'
      },
      accountBinding: {
        title: '帳號綁定',
        wechat: {
          bind: '綁定微信帳號',
          title: '微信掃碼關注公眾號，輸入「驗證碼」獲取驗證碼（三分鐘內有效）',
          placeholder: '驗證碼',
          confirm: '綁定'
        },
        github: '綁定 GitHub 帳號',
        lark: '綁定飛書帳號',
        email: {
          bind: '綁定電子郵件',
          title: '綁定電子郵件',
          placeholder: '輸入電子郵件',
          verificationCode: '驗證碼',
          getCode: '獲取驗證碼',
          resend: '重新發送({countdown})',
          confirm: '確認綁定',
          cancel: '取消'
        }
      },
      deleteAccount: {
        title: '危險操作',
        warning: '您正在刪除自己的帳號，將清空所有數據且不可恢復',
        placeholder: '輸入您的帳號 {username} 以確認刪除',
        confirm: '確認刪除',
        cancel: '取消',
        error: '請輸入您的帳號以確認刪除！'
      },
      messages: {
        tokenCopied: 'Token 已重設並已複製到剪貼板',
        inviteLinkCopied: '邀請連結已複製到剪貼板',
        systemTokenCopied: '系統 Token 已複製到剪貼板',
        wechatBindSuccess: '微信帳號已綁定！',
        emailBindSuccess: '電子信箱地址已綁定！',
        verificationCodeSent: '驗證碼已發送，請檢查您的電子郵件！',
        turnstileWait: '請稍後幾秒重試，Turnstile 正在檢查使用者環境！',
        accountDeleted: '帳號已刪除！'
      }
    },
    register: {
      title: '新用戶註冊',
      announcement: {
        title: '系統公告',
        content: '部分郵件服務（如 Outlook, Hotmail, Yahoo 等）可能無法收到系統信件，包括驗證信和客服郵件。\n請改用其他郵件服務。'
      },
      fields: {
        username: {
          placeholder: '輸入用戶名，最長 12 位'
        },
        password: {
          placeholder: '請輸入密碼，最短 8 位，最長 20 位',
          error: '密碼長度必須在 8 到 20 位之間',
          show: '顯示密碼',
          hide: '隱藏密碼'
        },
        confirmPassword: {
          placeholder: '請再次輸入密碼',
          error: '兩次輸入的密碼不一致'
        },
        email: {
          placeholder: '輸入電子信箱地址'
        },
        verificationCode: {
          placeholder: '輸入驗證碼',
          button: '發送驗證碼'
        }
      },
      buttons: {
        register: '註冊',
        login: '登入'
      },
      messages: {
        passwordLengthError: '密碼長度不得小於 8 位數！',
        passwordMismatch: '兩次輸入的密碼不一致',
        turnstileWait: '請稍後幾秒重試，Turnstile 正在檢查用戶環境！',
        registerSuccess: '註冊成功！',
        verificationCodeSent: '驗證碼發送成功，請檢查您的電子信箱！',
        enterEmailFirst: '請先填妥信箱地址，並點擊"發送驗證碼"按鈕'
      },
      loginPrompt: '已有帳號？'
    },
    tokensTable: {
      header: {
        title: 'API Key 管理',
        subtitle: '管理您的 API Keys 和權限設置',
        search: '搜尋 API Key 的名稱 ...',
        stats: {
          enabled: '已啟用的 API Key：{count} 個',
          neverExpire: '永不過期的 API Key：{count} 個'
        }
      },
      table: {
        headers: {
          name: '名稱',
          status: '狀態',
          usedQuota: '已用額度',
          remainQuota: '剩餘額度',
          createdTime: '建立時間',
          expireTime: '過期時間',
          actions: '操作'
        },
        status: {
          enabled: '已啟用',
          disabled: '已禁用',
          expired: '已過期',
          exhausted: '已用盡',
          unknown: '未知狀態'
        },
        values: {
          noName: '無',
          unlimited: '無限制',
          neverExpire: '永不過期'
        }
      },
      actions: {
        copy: '複製',
        chat: 'StimaChat 對話服務',
        playground: 'Stima Playground (beta)',
        delete: '刪除',
        enable: '啟用',
        disable: '停用',
        edit: '編輯',
        confirmDelete: '刪除 API Key『 {name}』'
      },
      buttons: {
        add: '新增 API Key',
        refresh: '重新整理'
      },
      sorting: {
        label: '排序方式',
        options: {
          default: '預設排序',
          remainQuota: '按剩餘額度排序',
          usedQuota: '按已用額度排序'
        }
      },
      messages: {
        copySuccess: '已複製到剪貼板！',
        copyError: '無法複製到剪貼板，請手動複製，已將 Token 填入搜尋框。',
        operationSuccess: '操作成功完成！'
      }
    },
    usersTable: {
      search: {
        placeholder: '搜尋使用者 ID，使用者名稱，顯示名稱，以及電子信箱地址 ...'
      },
      table: {
        headers: {
          id: 'ID',
          username: '使用者名稱',
          group: '群組',
          quota: '統計資訊',
          role: '使用者角色',
          status: '狀態',
          actions: '操作'
        },
        values: {
          noEmail: '未綁定電子信箱',
          remainingQuota: '剩餘額度',
          usedQuota: '已用額度',
          requestCount: '請求次數'
        }
      },
      roles: {
        user: '一般用户',
        admin: '系統管理員',
        superAdmin: '超級管理員',
        unknown: '未知身份'
      },
      status: {
        enabled: '已啟用',
        disabled: '已封鎖',
        unknown: '狀態未知'
      },
      actions: {
        promote: '提高權限',
        demote: '降低權限',
        delete: '刪除',
        deleteConfirm: '刪除使用者 {username}',
        enable: '啟用',
        disable: '禁用',
        edit: '編輯'
      },
      buttons: {
        addUser: '添加新的使用者',
        sort: {
          label: '排序方式',
          default: '預設排序',
          quota: '按剩餘額度排序',
          usedQuota: '按已用額度排序',
          requestCount: '按存取次數排序'
        }
      }
    },
    notFound: {
      title: '404 - 頁面不存在',
      description: {
        line1: '很抱歉，您所尋找的頁面不存在。',
        line2: '請檢查網址是否正確，或返回首頁。'
      },
      button: '返回首頁'
    },
    editUser: {
      title: '更新用戶資訊',
      fields: {
        username: {
          label: '用戶名',
          placeholder: '請輸入新的用戶名'
        },
        password: {
          label: '密碼',
          placeholder: '請輸入新的密碼，最短 8 位數'
        },
        displayName: {
          label: '顯示名稱',
          placeholder: '請輸入新的顯示名稱'
        },
        group: {
          label: '分組',
          placeholder: '請選擇分組',
          additionLabel: '請在系統設定頁面編輯分組倍率以添加新的分組：'
        },
        quota: {
          label: '剩餘額度',
          placeholder: '請輸入新的剩餘額度'
        },
        email: {
          label: '已綁定的郵箱帳號',
          placeholder: '此項唯讀，需要用戶通過個人設定頁面的相關綁定按鈕進行綁定，不可直接修改'
        }
      },
      buttons: {
        cancel: '取消',
        submit: '送出'
      },
      messages: {
        updateSuccess: '用戶資訊更新成功！'
      }
    },
    quotaStatus: {
      exhausted: '額度已用罄',
      lowQuota: '額度即將用罄'
    },
    logContent: {
      modelRatio: '模型倍率 %.2f',
      groupRatio: '分組倍率 %.2f',
      completionRatio: '補全倍率 %.2f'
    },
    quotaDisplay: {
      equivalent: '等值金額',
      point: '點額度',
      currency: '額度'
    },
    paymentConfirmation: {
      title: '付款後導向頁面',
      message: {
        completed: '已完成付款流程，我們已將交易結果發送到您的電子郵件地址。',
        redemptionCode: '若為交易成功，我們也已將加值碼發送到您的電子郵件地址，',
        checkEmail: '請至您的信箱（包括垃圾郵件匣）確認交易結果並獲得加值碼！'
      },
      support: '如果您在 15 分鐘內沒有收到加值碼，請聯繫我們的客服人員。',
      buttons: {
        topup: '前往加值',
        contact: '聯繫客服'
      }
    },
    redemption: {
      errors: {
        emptyCode: '未提供加值碼',
        invalidCode: '無效的加值碼',
        usedCode: '加值碼已被使用',
        invalidUserId: '無效的使用者 ID',
        topupFailed: '加值失敗',
        emptyId: 'id 為空！',
        topupRecord: '透過加值碼加值'
      }
    },
    misc: {
      errors: {
        invalidParams: '不符合格式的參數',
        emailDomainRestricted: '管理員已開啟電子信箱域名白名單，您的電子信箱地址的域名不在白名單中',
        emailTaken: '電子信箱已被使用',
        invalidRequest: '無效的參數',
        emailNotRegistered: '該電子信箱未註冊',
        resetLinkInvalid: '重設連結不存在或已過期'
      },
      email: {
        verification: {
          subject: '[%s] 電子信箱驗證',
          title: 'Stima API 電子信箱驗證',
          greeting: '親愛的用戶您好：',
          message: '感謝您使用 %s 服務。為了確保您的帳戶安全，我們需要驗證這個電子信箱地址確實為您所有。',
          code: '您的驗證碼：',
          expiry: '此驗證碼將於 %d 分鐘後失效',
          warning: '如果您並未申請此驗證，請忽略此信件。為了帳戶安全，請勿將驗證碼分享給他人。',
          footer: '此為系統自動發送的郵件，請勿直接回覆。',
          copyright: '© %d Stima AI, Inc. All Rights Reserved.'
        },
        passwordReset: {
          subject: '[%s] 重設密碼驗證',
          title: 'Stima API 密碼重設',
          greeting: '親愛的用戶您好：',
          message: '我們收到了您在 %s 的密碼重設要求。若這是您本人操作，請點擊下方按鈕進行密碼重設：',
          button: '重設密碼',
          contactSupport: '聯絡客服',
          linkHint: '如果按鈕無法點擊，請複製以下連結至瀏覽器開啟：',
          securityReminder: {
            title: '安全提醒：',
            expiry: '• 此連結將在 %d 分鐘後失效',
            share: '• 請勿將此連結分享給他人',
            contact: '• 如果您沒有要求重設密碼，請立即聯繫我們的客服團隊'
          },
          footer: {
            auto: '此為系統自動發送的郵件，請勿直接回覆',
            help: '若您需要協助，請聯繫客服',
            copyright: '© %d Stima AI, Inc. All Rights Reserved.'
          }
        },
        redemption: {
          subject: 'Stima API {planName} Redemption Code',
          title: 'Thank you for purchasing {planName}!',
          code: 'Your redemption code is:',
          instruction: 'Please visit Stima API Top-up Center to redeem your credits.',
          warning: 'Please note that the redemption code can only be used once. Keep it secure.',
          support: 'If you have any questions, please click the button below to contact our support team.',
          contactButton: 'Contact Support'
        }
      }
    },
    settings: {
      tabs: {
        personal: '個人設定'
      }
    }
  },
  'en': {
    mainTitle: 'We Make AI More Accessible',
    subTitle: 'Powerful API Services at Your Fingertips',
    description: 'Integrating 100+ AI Models with Chat & API Services',
    learnMore: 'Learn More',
    features: {
      oneClick: 'Quick Access',
      payAsYouGo: 'Pay As You Go',
      unlimited: 'Unlimited Rates',
      official: 'Official Provider'
    },
    featureDescs: {
      oneClick: 'Access to all AI models',
      payAsYouGo: 'No monthly fees',
      unlimited: 'Anytime, Anywhere',
      official: 'Secure data and transmissions'
    },
    sectionTitles: {
      oneStima: 'With Stima API, More Innovations',
      sectionDesc: 'Save more than 50% cost and get $3 platform credit as registration.',
      register: 'Register Now',
      modelList: 'Model List',
      features: 'Features',
      pricing: 'Pricing',
      contact: 'Contact Us'
    },
    partners: {
      stimaChat: 'StimaChat: Advanced Conversation System',
      immersive: 'Immersive Translate: Real-time Multilingual Translation',
      chatbox: 'Chatbox AI: Cross-platform Smart Assistant',
      cursor: 'Cursor IDE: Intelligent Development Environment',
      continue: 'Continue: AI Development Assistant',
      claude: 'Cline (prev. Claude Dev): AI Development Assistant',
      aider: 'Aider: AI Development Assistant'
    },
    comparison: {
      betterChoice: 'There\'s a Better Choice Now!',
      forEngineers: 'Higher usages with less cost, meeting all your needs.',
      valueProposition: '',
      compareItems: 'Comparison',
      priceComparison: 'What $20 Credits',
      cursorUsage: '500 fast requests (monthly reset)',
      stimaUsage: 'About 4400 fast requests, until credits are used up',
      paymentModel: 'Payment',
      cursorPayment: 'Fixed monthly fee, pay even for unused quota',
      stimaPayment: 'Pay As You Go, only pay for what you use',
      highDemand: 'High Usage Users',
      cursorLimit: 'Slow response after 500 requests',
      stimaLimit: 'Fast requests with no limits',
      throughputComparison: 'At the same price, Stima offers up to 8.8x code throughput.',
      testNote: 'Based on internal testing with Anthropic Claude 3.5 Sonnet 20240620 model rates, processing approximately 300 lines of code per request. For reference only.'
    },
    advantages: {
      title: 'Why Choose Stima API?',
      subtitle: 'Discover the Power of AI with Unmatched Benefits',
      items: {
        pricing: {
          title: 'Affordable Pricing',
          desc: 'Pay less for the same APIs compared to official providers'
        },
        noFees: {
          title: 'No Monthly Fees',
          desc: 'Say goodbye to $20 subscriptions with our pay-as-you-go model'
        },
        credit: {
          title: 'Free Credit',
          desc: 'Get started with $3 platform credit upon registration'
        },
        models: {
          title: 'All AI Models',
          desc: 'Access all AI model available on the market'
        },
        tools: {
          title: 'Cutting-Edge Tools',
          desc: 'Explore web search with StimaChat and Stima Playground'
        },
        performance: {
          title: 'Enhanced Performance',
          desc: 'Up to 8.8x more code generation with Cursor integration'
        },
        scalability: {
          title: 'High-Frequency Ready',
          desc: 'Handle large volumes of API calls effortlessly'
        },
        documentation: {
          title: 'Full Documentation',
          desc: 'Access comprehensive API docs for faster integration'
        },
        experts: {
          title: 'Expert Roles',
          desc: 'Multiple expert roles in StimaChat and Playground for focused professional solutions'
        }
      }
    },
    stimaChat: {
      title: 'StimaChat - The Future of Intelligent Conversation',
      features: {
        naturalConversation: {
          title: 'Smart Conversation Flow',
          desc: 'Automatically identifies topic changes and starts new conversations',
          whyChoose: 'Why Choose Us?',
          benefits: {
            security: {
              title: 'Secure & Reliable',
              desc: 'Encrypted data transmission ensures your privacy'
            },
            speed: {
              title: 'Fast Response',
              desc: 'Optimized architecture for real-time conversation'
            },
            developer: {
              title: 'Developer Friendly',
              desc: 'Complete API documentation and development tools'
            },
            pricing: {
              title: 'Better Pricing',
              desc: 'Pay-per-use, no subscription, better value'
            }
          },
          newFeature: {
            title: 'New Features Released!',
            desc: 'Now with code generation and web search capabilities'
          },
          tryNow: 'Try Now'
        },
        deepUnderstanding: {
          title: 'Deep Understanding',
          desc: 'Precisely captures your ideas through personalized analysis'
        },
        smartPrompt: {
          title: 'Smart Text Generation',
          desc: 'Auto Prompt system helps optimize prompts for ideal outputs'
        },
        flexibleGoal: {
          title: 'Secure Data Handling',
          desc: 'Chat history stored only on client side for privacy'
        }
      },
      whyChoose: 'Why Choose StimaChat?',
      benefits: {
        security: {
          title: 'Secure & Reliable',
          desc: 'Encrypted data transmission ensures your privacy'
        },
        speed: {
          title: 'Fast Response',
          desc: 'Optimized service architecture for real-time conversation experience'
        },
        developer: {
          title: 'Developer Friendly',
          desc: 'Complete API documentation and development tool support'
        },
        pricing: {
          title: 'Better Pricing',
          desc: 'Pay-per-use, no subscription required, better value for money'
        }
      },
      newFeature: {
        title: 'New Features Released!',
        desc: 'Now supporting code generation and web search, making software development more efficient.'
      },
      tryNow: 'Try Now'
    },
    stimaFeatures: {
      title: 'Revolutionary AI Conversation Experience',
      subtitle: 'Redefining AI Interaction',
      features: {
        userInterface: {
          title: 'Superior User Experience',
          desc: 'Intuitive interface with smoother interactions and responses'
        },
        codeSupport: {
          title: 'Comprehensive Development Support',
          desc: 'Code generation and LaTex rendering for complete development experience'
        },
        webSearch: {
          title: 'Smart Search Integration',
          desc: 'Web search capability across all AI models for real-time information'
        },
        smartCompression: {
          title: 'Long Conversation Optimization',
          desc: 'Smart compression while maintaining conversation coherence'
        },
        aiRoles: {
          title: 'Professional Role Customization',
          desc: 'Multiple AI expert roles for focused, professional conversations'
        },
        conversation: {
          title: 'Intelligent Conversation Management',
          desc: 'Auto-categorization for easier history navigation'
        },
        naturalConversation: {
          title: 'Natural Conversation Experience',
          desc: 'Smart topic identification and context management',
          whyChoose: 'Why Choose Us?',
          benefits: {
            security: {
              title: 'Secure & Reliable',
              desc: 'Encrypted data transmission ensures your privacy'
            },
            speed: {
              title: 'Fast Response',
              desc: 'Optimized architecture for real-time conversation'
            },
            developer: {
              title: 'Developer Friendly',
              desc: 'Complete API documentation and development tools'
            },
            pricing: {
              title: 'Better Pricing',
              desc: 'Pay-per-use, no subscription, better value'
            }
          },
          newFeature: {
            title: 'New Features Released!',
            desc: 'Now with code generation and web search capabilities'
          },
          tryNow: 'Try Now'
        }
      }
    },
    pricing: {
      title: 'Model Pricing & Billing Units'
    },
    statistics: {
      topAd: {
        title: 'Over',
        number: '1000+',
        description: 'users choose our service'
      },
      bottomStats: {
        availability: {
          number: '99.9%',
          label: 'Service Availability'
        },
        models: {
          number: '100+',
          label: 'AI Models'
        }
      }
    },
    exchangeRate: {
      title: 'Better Exchange Rate',
      description: {
        line1: 'Get platform credits at rates',
        line2: 'below market price!'
      },
      rate: {
        from: '0.5 USD',
        to: '1 USD (Platform Credit)'
      }
    },
    search: {
      placeholder: 'Search model name, provider, or model alias...'
    },
    filter: {
      all: 'All',
      morePublishers: 'More Providers'
    },
    table: {
      headers: {
        modelName: 'Model Name',
        publisher: 'Provider',
        billingUnit: 'Billing',
        price: 'Price (USD/K tokens)',
        modelAlias: 'Model Alias'
      },
      noResults: {
        header: 'No models found',
        content: 'Please try different keywords'
      },
      copied: 'Copied!',
      clickToCopy: 'Click to copy'
    },
    billing: {
      payPerUse: 'Pay As You Go',
      payPerRequest: 'Pay Per Request',
      free: 'Free'
    },
    footer: {
      description: 'We are committed to making AI technology more accessible, providing innovative AI solutions.',
      services: {
        title: 'Services',
        items: {
          apiGuide: 'Documentation',
          chat: 'StimaChat Conversation System',
          research: 'Research',
          playground: 'Playground',
          status: 'Status',
          cursorHistory: 'Cursor Archives'
        }
      },
      contact: {
        title: 'Contact Us'
      }
    },
    navigation: {
      guide: 'Docs',
      chat: 'Chat',
      channel: 'Channel',
      purchase: 'Purchase',
      apiKey: 'Keys',
      modelList: 'Models',
      redemption: 'Redemption',
      topup: 'Credits',
      user: 'User',
      log: 'Activity',
      settings: 'Settings'
    },
    auth: {
      login: 'Login',
      logout: 'Logout',
      register: 'Register'
    },
    payment: {
      title: 'Stima API Plans',
      subtitle: 'Powerful and Flexible API Services',
      viewPlans: 'View Plans',
      table: {
        headers: {
          planName: 'Name',
          apiCredit: 'API Credit',
          price: 'Price (USD) (excl. tax)',
          select: 'Select Plan'
        },
        plans: {
          starter: {
            name: 'Starter Plan',
            price: 'USD 2.5'
          },
          basic: {
            name: 'Basic Plan',
            price: 'USD 4'
          },
          standard: {
            name: 'Standard Plan',
            price: 'USD 8'
          },
          premium: {
            name: 'Premium Plan',
            price: 'USD 12.5'
          }
        },
        select: 'Select'
      },
      customPlan: {
        text: 'For custom plans or higher quotas, please contact our enterprise solutions team',
        email: 'Email'
      },
      policy: {
        title: 'Service Policies',
        description: 'You can view our complete policies here',
        refund: 'Refund Policy',
        privacy: 'Privacy Policy',
        terms: 'Terms of Service'
      },
      selectedPlan: {
        title: 'Your Selected Plan',
        name: 'Plan Name:',
        price: 'Price:',
        priceUnit: 'TWD (excl. tax)',
        emailPrompt: 'Please enter your email address below to receive the top-up code'
      },
      orderForm: {
        title: 'Order Information',
        email: {
          label: 'Email Address:',
          placeholder: 'Please enter your email address to receive the top-up code'
        },
        agreement: {
          text: 'I have read and agree to Stima AI\'s'
        },
        submit: 'Proceed to Payment'
      },
      security: 'Your payment information will be encrypted and securely transmitted',
      announcement: {
        message: 'System Announcement: Some email services (e.g., Outlook, Hotmail, Yahoo) may not receive system emails, including top-up codes and customer service emails. Please use alternative email services.'
      },
      nav: {
        home: 'Home',
        contact: 'Contact Us'
      },
      email: {
        redemption: {
          subject: 'Stima API {planName} Redemption Code',
          title: 'Thank you for purchasing {planName}!',
          code: 'Your redemption code is:',
          instruction: 'Please visit Stima API Top-up Center to redeem your credits.',
          warning: 'Please note that the redemption code can only be used once. Keep it secure.',
          support: 'If you have any questions, please click the button below to contact our support team.',
          contactButton: 'Contact Support'
        }
      }
    },
    token: {
      title: 'API Key Management',
      edit: {
        updateTitle: 'Update API Key Information',
        createTitle: 'Create New API Key',
        updateSubtitle: 'Modify existing API Key settings',
        createSubtitle: 'Create a new API Key and set its permissions',
        name: 'Name',
        namePlaceholder: 'Please enter name',
        models: 'Model Scope',
        modelsPlaceholder: 'Please select allowed models, leave empty for no restrictions',
        ipLimit: 'IP Restriction',
        ipLimitPlaceholder: 'Enter allowed IPs, e.g.: 192.168.0.0/24, use commas to separate multiple IPs. Leave empty for no restrictions.',
        expireTime: 'Expiration Time',
        expireTimePlaceholder: 'Enter expiration time in yyyy-MM-dd HH:mm:ss format, -1 for no limit',
        expireButtons: {
          never: 'Never Expires',
          oneMonth: 'Expires in 1 Month',
          oneDay: 'Expires in 1 Day',
          oneHour: 'Expires in 1 Hour',
          oneMinute: 'Expires in 1 Minute'
        },
        quotaInfo: {
          title: 'Quota Usage Information',
          content: 'The API Key quota only limits the maximum usage of the API Key, actual usage will be limited by the account\'s remaining quota.'
        },
        quota: 'Quota',
        quotaPlaceholder: 'Please enter quota',
        unlimitedQuota: {
          enable: 'Set Unlimited Quota',
          disable: 'Cancel Unlimited Quota'
        },
        buttons: {
          cancel: 'Cancel',
          submit: 'Submit'
        },
        messages: {
          updateSuccess: 'API Key updated successfully!',
          createSuccess: 'API Key created successfully. Please go to the list page and click copy to get your API Key!',
          invalidExpireTime: 'Invalid expiration time format!'
        }
      }
    },
    topup: {
      title: 'Credit Management Center',
      subtitle: 'Manage your API credits, add credits anytime to ensure uninterrupted service',
      quotaManagement: {
        title: 'Add Credits',
        subtitle: 'Enter redemption code or proceed to purchase page',
        redemptionCode: {
          label: 'Redemption Code',
          placeholder: 'Please enter redemption code',
          invalidCode: 'Please enter a valid redemption code',
          requestFailed: 'Top-up request failed, please try again later'
        },
        success: {
          title: 'Top-up Successful!',
          message: 'Your credit has been updated'
        },
        buttons: {
          purchase: 'Purchase Credits',
          redeem: 'Use Redemption Code',
          processing: 'Processing...'
        },
        popup: {
          hint: 'Please enter redemption code first'
        },
        errors: {
          noTopUpLink: 'Add credits link not set by system administrator!'
        }
      },
      currentQuota: {
        title: 'Current Credit',
        available: 'Available Credit'
      },
      features: {
        security: 'Secure & Reliable Credit Management',
        instant: 'Add Credits Instantly',
        service: '24/7 Service Available'
      },
      logs: {
        title: 'Usage Details',
        totalQuota: 'Total Quota Used',
        clickToView: 'Click to View',
        fields: {
          apiKeyName: 'API Key Name',
          modelName: 'Model Name',
          startTime: 'Start Time',
          endTime: 'End Time',
          channelId: 'Channel ID',
          username: 'Username',
          optional: 'Optional'
        },
        query: 'Query',
        refresh: 'Refresh',
        types: {
          all: 'All',
          topup: 'Top-up',
          consume: 'Consume',
          manage: 'Manage',
          system: 'System',
          unknown: 'Unknown'
        },
        tableHeaders: {
          time: 'Time',
          channel: 'Channel',
          user: 'User',
          apiKey: 'API Key',
          type: 'Type',
          model: 'Model',
          inputTokens: 'Input Tokens',
          outputTokens: 'Output Tokens',
          quota: 'Quota Used',
          details: 'Details',
          actions: 'Actions'
        },
        filterType: 'Select Log Type',
        pagination: {
          jumpTo: 'Jump to',
          page: 'Page',
          total: 'Total'
        }
      }
    },
    logs: {
      title: 'Usage Details',
      totalQuota: 'Total Quota Used',
      clickToView: 'Click to View',
      fields: {
        apiKeyName: 'API Key Name',
        modelName: 'Model Name',
        startTime: 'Start Time',
        endTime: 'End Time',
        channelId: 'Channel ID',
        username: 'Username',
        optional: 'Optional'
      },
      query: 'Query',
      refresh: 'Refresh',
      types: {
        all: 'All',
        topup: 'Top-up',
        consume: 'Consume',
        manage: 'Manage',
        system: 'System',
        unknown: 'Unknown'
      },
      tableHeaders: {
        time: 'Time',
        channel: 'Channel',
        user: 'User',
        apiKey: 'API Key',
        type: 'Type',
        model: 'Model',
        inputTokens: 'Input Tokens',
        outputTokens: 'Output Tokens',
        quota: 'Quota Used',
        details: 'Details',
        actions: 'Actions'
      },
      filterType: 'Select Log Type',
      pagination: {
        jumpTo: 'Jump to',
        page: 'Page',
        total: 'Total'
      }
    },
    login: {
      title: 'Login',
      fields: {
        username: {
          placeholder: 'Username / Email'
        },
        password: {
          placeholder: 'Password',
          show: 'Show Password',
          hide: 'Hide Password'
        },
        rememberMe: 'Remember Me'
      },
      buttons: {
        login: 'Login',
        forgotPassword: 'Forgot Password',
        register: 'Sign Up'
      },
      thirdParty: {
        title: 'Third-party Login',
        wechat: {
          title: 'WeChat Login',
          scanQRCode: 'Scan the QR code to follow the official account and enter the verification code to get the login code (valid for 3 minutes)',
          verificationCode: 'Enter Verification Code',
          confirm: 'Confirm Login'
        }
      },
      messages: {
        loginExpired: 'Not logged in or login expired, please login again!',
        loginSuccess: 'Login successful!',
        changeDefaultPassword: 'Please change your default password immediately!'
      }
    },
    passwordReset: {
      title: 'Password Reset Confirmation',
      fields: {
        email: {
          placeholder: 'Email Address'
        },
        newPassword: {
          placeholder: 'New Password'
        }
      },
      buttons: {
        submit: 'Submit',
        completed: 'Password Reset Completed'
      },
      messages: {
        passwordCopied: 'New password has been copied to clipboard: ',
        copySuccess: 'Password has been copied to clipboard: '
      }
    },
    passwordResetForm: {
      title: 'Reset Password',
      description: 'Please enter your registered email address, and we will send you a password reset link.',
      fields: {
        email: {
          placeholder: 'Enter your registered email address',
          error: 'Please enter a valid email address'
        }
      },
      buttons: {
        submit: 'Send Reset Link',
        retry: 'Retry in ({countdown}s)'
      },
      messages: {
        turnstileWait: 'Please wait a few seconds and try again, Turnstile is verifying the user environment!',
        resetEmailSent: 'Reset email has been sent, please check your inbox!'
      }
    },
    personalSetting: {
      title: 'General Settings',
      buttons: {
        updateProfile: 'Update Profile (Change Account and Password)',
        generateToken: 'Generate System Access Secret',
        copyInviteLink: 'Copy Invite Link (Not Available Yet)',
        deleteAccount: 'Delete Account'
      },
      accountBinding: {
        title: 'Account Binding',
        wechat: {
          bind: 'Bind WeChat Account',
          title: 'Scan QR code to follow official account and enter verification code (valid for 3 minutes)',
          placeholder: 'Verification Code',
          confirm: 'Bind'
        },
        github: 'Bind GitHub Account',
        lark: 'Bind Lark Account',
        email: {
          bind: 'Bind Email',
          title: 'Bind Email',
          placeholder: 'Enter Email',
          verificationCode: 'Verification Code',
          getCode: 'Get Code',
          resend: 'Resend ({countdown})',
          confirm: 'Confirm Binding',
          cancel: 'Cancel'
        }
      },
      deleteAccount: {
        title: 'Dangerous Operation',
        warning: 'You are deleting your account. All data will be cleared and cannot be recovered',
        placeholder: 'Enter your username {username} to confirm deletion',
        confirm: 'Confirm Delete',
        cancel: 'Cancel',
        error: 'Please enter your username to confirm deletion!'
      },
      messages: {
        tokenCopied: 'Token has been reset and copied to clipboard',
        inviteLinkCopied: 'Invite link has been copied to clipboard',
        systemTokenCopied: 'System token has been copied to clipboard',
        wechatBindSuccess: 'WeChat account has been bound!',
        emailBindSuccess: 'Email address has been bound!',
        verificationCodeSent: 'Verification code has been sent, please check your email!',
        turnstileWait: 'Please wait a few seconds and try again, Turnstile is verifying the user environment!',
        accountDeleted: 'Account has been deleted!'
      }
    },
    register: {
      title: 'Sign Up',
      announcement: {
        title: 'System Announcement',
        content: 'Some email services (e.g., Outlook, Hotmail, Yahoo) may not receive system emails, including verification and customer service emails.\nPlease use alternative email services.'
      },
      fields: {
        username: {
          placeholder: 'Enter username (max 12 characters)'
        },
        password: {
          placeholder: 'Enter password (8-20 characters)',
          error: 'Password must be between 8 and 20 characters',
          show: 'Show Password',
          hide: 'Hide Password'
        },
        confirmPassword: {
          placeholder: 'Confirm password',
          error: 'Passwords do not match'
        },
        email: {
          placeholder: 'Enter email address'
        },
        verificationCode: {
          placeholder: 'Enter verification code',
          button: 'Send Code'
        }
      },
      buttons: {
        register: 'Register',
        login: 'Login'
      },
      messages: {
        passwordLengthError: 'Password must be at least 8 characters!',
        passwordMismatch: 'Passwords do not match',
        turnstileWait: 'Please wait a few seconds, Turnstile is verifying the user environment!',
        registerSuccess: 'Registration successful!',
        verificationCodeSent: 'Verification code sent successfully, please check your email!',
        enterEmailFirst: 'Please enter your email address first and click "Send Code" button'
      },
      loginPrompt: 'Already Registered?'
    },
    tokensTable: {
      header: {
        title: 'API Key Management',
        subtitle: 'Manage your API Keys and permission settings',
        search: 'Search API Key name ...',
        stats: {
          enabled: 'Enabled API Keys: {count}',
          neverExpire: 'Never Expire API Keys: {count}'
        }
      },
      table: {
        headers: {
          name: 'Name',
          status: 'Status',
          usedQuota: 'Used Quota',
          remainQuota: 'Remaining Quota',
          createdTime: 'Created Time',
          expireTime: 'Expire Time',
          actions: 'Actions'
        },
        status: {
          enabled: 'Enabled',
          disabled: 'Disabled',
          expired: 'Expired',
          exhausted: 'Exhausted',
          unknown: 'Unknown Status'
        },
        values: {
          noName: 'None',
          unlimited: 'Unlimited',
          neverExpire: 'Never Expire'
        }
      },
      actions: {
        copy: 'Copy',
        chat: 'StimaChat Service',
        playground: 'Stima Playground (beta)',
        delete: 'Delete',
        enable: 'Enable',
        disable: 'Disable',
        edit: 'Edit',
        confirmDelete: 'Delete API Key "{name}"'
      },
      buttons: {
        add: 'Add API Key',
        refresh: 'Refresh'
      },
      sorting: {
        label: 'Sort By',
        options: {
          default: 'Default Order',
          remainQuota: 'Sort by Remaining Quota',
          usedQuota: 'Sort by Used Quota'
        }
      },
      messages: {
        copySuccess: 'Copied to clipboard!',
        copyError: 'Unable to copy to clipboard, please copy manually. Token has been filled in the search box.',
        operationSuccess: 'Operation completed successfully!'
      }
    },
    usersTable: {
      search: {
        placeholder: 'Search user ID, username, display name, and email address ...'
      },
      table: {
        headers: {
          id: 'ID',
          username: 'Username',
          group: 'Group',
          quota: 'Statistics',
          role: 'User Role',
          status: 'Status',
          actions: 'Actions'
        },
        values: {
          noEmail: 'No Email Bound',
          remainingQuota: 'Remaining Quota',
          usedQuota: 'Used Quota',
          requestCount: 'Request Count'
        }
      },
      roles: {
        user: 'Regular User',
        admin: 'System Admin',
        superAdmin: 'Super Admin',
        unknown: 'Unknown Role'
      },
      status: {
        enabled: 'Enabled',
        disabled: 'Blocked',
        unknown: 'Unknown Status'
      },
      actions: {
        promote: 'Promote',
        demote: 'Demote',
        delete: 'Delete',
        deleteConfirm: 'Delete User {username}',
        enable: 'Enable',
        disable: 'Disable',
        edit: 'Edit'
      },
      buttons: {
        addUser: 'Add New User',
        sort: {
          label: 'Sort By',
          default: 'Default Order',
          quota: 'Sort by Remaining Quota',
          usedQuota: 'Sort by Used Quota',
          requestCount: 'Sort by Request Count'
        }
      }
    },
    notFound: {
      title: '404 - Page Not Found',
      description: {
        line1: 'Sorry, the page you are looking for does not exist.',
        line2: 'Please check the URL or return to the homepage.'
      },
      button: 'Back to Home'
    },
    editUser: {
      title: 'Update User Information',
      fields: {
        username: {
          label: 'Username',
          placeholder: 'Enter new username'
        },
        password: {
          label: 'Password',
          placeholder: 'Enter new password (minimum 8 characters)'
        },
        displayName: {
          label: 'Display Name',
          placeholder: 'Enter new display name'
        },
        group: {
          label: 'Group',
          placeholder: 'Select group',
          additionLabel: 'Please edit group multipliers in system settings to add new groups:'
        },
        quota: {
          label: 'Remaining Quota',
          placeholder: 'Enter new remaining quota'
        },
        email: {
          label: 'Bound Email Account',
          placeholder: 'Read-only field, users need to bind through the personal settings page, cannot be modified directly'
        }
      },
      buttons: {
        cancel: 'Cancel',
        submit: 'Submit'
      },
      messages: {
        updateSuccess: 'User information updated successfully!'
      }
    },
    quotaStatus: {
      exhausted: 'Credit Exhausted',
      lowQuota: 'Credit Running Low'
    },
    logContent: {
      modelRatio: 'Model Rate %.2f',
      groupRatio: 'Group Rate %.2f',
      completionRatio: 'Completion Rate %.2f'
    },
    quotaDisplay: {
      equivalent: 'Equivalent Amount',
      point: 'Points',
      currency: 'Credits'
    },
    paymentConfirmation: {
      title: 'Payment Confirmation Page',
      message: {
        completed: 'Payment process completed. We have sent the transaction result to your email address.',
        redemptionCode: 'If the transaction was successful, we have also sent the redemption code to your email address,',
        checkEmail: 'Please check your inbox (including spam folder) for the transaction result and redemption code!'
      },
      support: 'If you do not receive the redemption code within 15 minutes, please contact our customer service.',
      buttons: {
        topup: 'Go to Credits Center',
        contact: 'Contact Support'
      }
    },
    redemption: {
      errors: {
        emptyCode: 'Redemption code not provided',
        invalidCode: 'Invalid redemption code',
        usedCode: 'Redemption code has been used',
        invalidUserId: 'Invalid user ID',
        topupFailed: 'Top-up failed',
        emptyId: 'ID is empty!',
        topupRecord: 'Top-up via redemption code'
      }
    },
    misc: {
      errors: {
        invalidParams: 'Invalid parameter format',
        emailDomainRestricted: 'Administrator has enabled email domain whitelist, your email domain is not in the whitelist',
        emailTaken: 'Email is already taken',
        invalidRequest: 'Invalid parameters',
        emailNotRegistered: 'This email is not registered',
        resetLinkInvalid: 'Reset link does not exist or has expired'
      },
      email: {
        verification: {
          subject: '[%s] Email Verification',
          title: 'Stima API Email Verification',
          greeting: 'Dear User,',
          message: 'Thank you for using %s service. To ensure your account security, we need to verify that this email address belongs to you.',
          code: 'Your verification code:',
          expiry: 'This verification code will expire in %d minutes',
          warning: 'If you did not request this verification, please ignore this email. For account security, please do not share the verification code with others.',
          footer: 'This is an automated email, please do not reply directly.',
          copyright: '© %d Stima AI, Inc. All Rights Reserved.'
        },
        passwordReset: {
          subject: '[%s] Password Reset Verification',
          title: 'Stima API Password Reset',
          greeting: 'Dear User,',
          message: 'We received a password reset request for your account at %s. If this was you, please click the button below to reset your password:',
          button: 'Reset Password',
          contactSupport: 'Contact Support',
          linkHint: 'If the button is not clickable, please copy and paste the following link into your browser:',
          securityReminder: {
            title: 'Security Reminder:',
            expiry: '• This link will expire in %d minutes',
            share: '• Do not share this link with others',
            contact: '• If you did not request a password reset, please contact our support team immediately'
          },
          footer: {
            auto: 'This is an automated email, please do not reply directly',
            help: 'If you need assistance, please contact support',
            copyright: '© %d Stima AI, Inc. All Rights Reserved.'
          }
        },
        redemption: {
          subject: 'Stima API {planName} Redemption Code',
          title: 'Thank you for purchasing {planName}!',
          code: 'Your redemption code is:',
          instruction: 'Please visit Stima API Top-up Center to redeem your credits.',
          warning: 'Please note that the redemption code can only be used once. Keep it secure.',
          support: 'If you have any questions, please click the button below to contact our support team.',
          contactButton: 'Contact Support'
        }
      }
    },
    settings: {
      tabs: {
        personal: 'Personal Settings'
      }
    }
  }
}; 