import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import TokensTable from '../../components/TokensTable';
import { getUserLanguage, translations } from '../../utils/language';

const Token = () => {
  const currentLanguage = getUserLanguage();
  const t = translations[currentLanguage];

  return (
    <>
      <Segment>
        <Header as='h3'>{t.token.title}</Header>
        <TokensTable />
      </Segment>
    </>
  );
};

export default Token;
