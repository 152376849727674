import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import { API, showError, showInfo, showSuccess } from '../helpers';
import Turnstile from 'react-turnstile';
import styled, { keyframes } from 'styled-components';
import { getUserLanguage, translations } from '../utils/language';

// 動態背景元素
const DynamicBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 20% 30%, rgba(74, 144, 226, 0.08) 0%, transparent 50%),
              radial-gradient(circle at 80% 70%, rgba(52, 152, 219, 0.08) 0%, transparent 50%),
              radial-gradient(circle at 50% 50%, rgba(41, 128, 185, 0.05) 0%, transparent 70%);
  animation: pulse 8s ease-in-out infinite alternate;

  @keyframes pulse {
    0% { opacity: 0.3; }
    50% { opacity: 0.5; }
    100% { opacity: 0.3; }
  }
`;

const FloatingShape1 = styled.div`
  position: absolute;
  top: 10%;
  right: 15%;
  width: 200px;
  height: 200px;
  background: linear-gradient(135deg, rgba(74, 144, 226, 0.2) 0%, rgba(52, 152, 219, 0.2) 100%);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: morphing 15s ease-in-out infinite;
  opacity: 0.7;

  @keyframes morphing {
    0% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
    25% { border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%; }
    50% { border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%; }
    75% { border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%; }
    100% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
  }
`;

const FloatingShape2 = styled.div`
  position: absolute;
  bottom: 15%;
  left: 10%;
  width: 150px;
  height: 150px;
  background: linear-gradient(135deg, rgba(52, 152, 219, 0.2) 0%, rgba(41, 128, 185, 0.2) 100%);
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  animation: morphing 12s ease-in-out infinite reverse;
  opacity: 0.5;
`;

// 修改 StyledGrid，使用 Container 代替 Grid
const PageContainer = styled.div`
  padding-top: 80px;
  min-height: 100vh;
  background: linear-gradient(135deg, 
    rgba(255, 255, 255, 0.97) 0%,
    rgba(247, 249, 252, 0.97) 100%
  );
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding: 0 20px;
`;

const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: 2em;
  writing-mode: horizontal-tb !important;
`;

const StyledLogo = styled(Image)`
  width: 120px !important;
  height: auto !important;
  margin: 0 auto 1em auto !important;
  display: block !important;
`;

const StyledHeader = styled(Header)`
  font-size: 2.5em !important;
  font-weight: 800 !important;
  color: #2c3e50 !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
  margin: 0.5em 0 !important;
  writing-mode: horizontal-tb !important;
  text-orientation: mixed !important;
  direction: ltr !important;
`;

const StyledSegment = styled(Segment)`
  background: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(20px) !important;
  padding: 2em !important;
  border-radius: 30px !important;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
`;

const StyledFormInput = styled(Form.Input)`
  writing-mode: horizontal-tb !important;
  
  input {
    writing-mode: horizontal-tb !important;
    border-radius: 50px !important;
    padding: 15px 25px !important;
    border: 2px solid rgba(52, 152, 219, 0.3) !important;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
    font-size: 1.1em !important;
    transition: all 0.3s ease !important;

    &:focus {
      border-color: #3498db !important;
      box-shadow: 0 6px 20px rgba(52, 152, 219, 0.15) !important;
    }
  }
`;

const StyledButton = styled(Button)`
  writing-mode: horizontal-tb !important;
  text-orientation: mixed !important;
  background: linear-gradient(135deg, #3498db 0%, #2980b9 100%) !important;
  color: white !important;
  padding: 1.2em !important;
  font-size: 1.1em !important;
  font-weight: 600 !important;
  border-radius: 50px !important;
  border: none !important;
  box-shadow: 0 10px 20px rgba(52, 152, 219, 0.3) !important;
  transition: all 0.3s ease !important;

  &:hover:not(:disabled) {
    transform: translateY(-3px) scale(1.05) !important;
    box-shadow: 0 15px 30px rgba(52, 152, 219, 0.4) !important;
  }

  &:active:not(:disabled) {
    transform: translateY(1px) !important;
  }

  &:disabled {
    opacity: 0.7 !important;
    cursor: not-allowed !important;
  }
`;

const StyledMessage = styled(Message)`
  background: rgba(255, 255, 255, 0.9) !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
  margin-bottom: 2em !important;
  writing-mode: horizontal-tb !important;
  text-orientation: mixed !important;
  
  span {
    writing-mode: horizontal-tb !important;
    text-orientation: mixed !important;
  }
`;

const PasswordResetForm = () => {
  const currentLanguage = getUserLanguage();
  const t = translations[currentLanguage].passwordResetForm;

  const [inputs, setInputs] = useState({
    email: ''
  });
  const { email } = inputs;

  const [loading, setLoading] = useState(false);
  const [turnstileEnabled, setTurnstileEnabled] = useState(false);
  const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      if (status.turnstile_check) {
        setTurnstileEnabled(true);
        setTurnstileSiteKey(status.turnstile_site_key);
      }
    }
  }, []);

  useEffect(() => {
    let countdownInterval = null;
    if (disableButton && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setDisableButton(false);
      setCountdown(30);
    }
    return () => clearInterval(countdownInterval);
  }, [disableButton, countdown]);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
    if (name === 'email') {
      validateEmail(value);
    }
  }

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
  }

  async function handleSubmit(e) {
    setDisableButton(true);
    if (!email) return;
    if (turnstileEnabled && turnstileToken === '') {
      showInfo(t.messages.turnstileWait);
      return;
    }
    setLoading(true);
    const res = await API.get(
      `/api/reset_password?email=${email}&turnstile=${turnstileToken}`
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess(t.messages.resetEmailSent);
      setInputs({ ...inputs, email: '' });
    } else {
      showError(message);
    }
    setLoading(false);
  }

  return (
    <PageContainer>
      <DynamicBackground />
      <FloatingShape1 />
      <FloatingShape2 />
      <ContentWrapper>
        <LogoContainer>
          <StyledLogo src='/logo.png' />
          <StyledHeader as='h2' textAlign='center'>
            {t.title}
          </StyledHeader>
        </LogoContainer>

        <StyledMessage info>
          {t.description}
        </StyledMessage>

        <Form size='large'>
          <StyledSegment>
            <StyledFormInput
              fluid
              icon='mail'
              iconPosition='left'
              placeholder={t.fields.email.placeholder}
              name='email'
              value={email}
              onChange={handleChange}
              error={email !== '' && !isValidEmail && {
                content: t.fields.email.error,
                pointing: 'below'
              }}
            />
            
            {turnstileEnabled && (
              <div style={{ margin: '1em 0', display: 'flex', justifyContent: 'center' }}>
                <Turnstile
                  sitekey={turnstileSiteKey}
                  onVerify={(token) => {
                    setTurnstileToken(token);
                  }}
                />
              </div>
            )}

            <StyledButton
              fluid
              size='large'
              onClick={handleSubmit}
              loading={loading}
              disabled={disableButton || !isValidEmail}
            >
              {disableButton ? t.buttons.retry.replace('{countdown}', countdown) : t.buttons.submit}
            </StyledButton>
          </StyledSegment>
        </Form>
      </ContentWrapper>
    </PageContainer>
  );
};

export default PasswordResetForm;
