import React, { useEffect, useState } from 'react';
import { Button, Form, Header, Label, Pagination, Segment, Select, Table } from 'semantic-ui-react';
import { API, isAdmin, showError, timestamp2string } from '../helpers';
import { ITEMS_PER_PAGE } from '../constants';
import { renderQuota } from '../helpers/render';
import { getUserLanguage, translations } from '../utils/language';

function renderTimestamp(timestamp) {
  return (
    <>
      {timestamp2string(timestamp)}
    </>
  );
}

const LogsTable = () => {
  const currentLanguage = getUserLanguage();
  const t = translations[currentLanguage].logs;

  const MODE_OPTIONS = [
    { key: 'all', text: t.types.all, value: 'all' },
    { key: 'self', text: t.types.self, value: 'self' }
  ];

  const LOG_OPTIONS = [
    { key: '0', text: t.types.all, value: 0 },
    { key: '1', text: t.types.topup, value: 1 },
    { key: '2', text: t.types.consume, value: 2 },
    { key: '3', text: t.types.manage, value: 3 },
    { key: '4', text: t.types.system, value: 4 }
  ];

  function renderType(type) {
    switch (type) {
      case 1:
        return <Label basic color='green'>{t.types.topup}</Label>;
      case 2:
        return <Label basic color='olive'>{t.types.consume}</Label>;
      case 3:
        return <Label basic color='orange'>{t.types.manage}</Label>;
      case 4:
        return <Label basic color='purple'>{t.types.system}</Label>;
      default:
        return <Label basic color='black'>{t.types.unknown}</Label>;
    }
  }

  const [logs, setLogs] = useState([]);
  const [showStat, setShowStat] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searching, setSearching] = useState(false);
  const [logType, setLogType] = useState(0);
  const isAdminUser = isAdmin();
  let now = new Date();
  const [inputs, setInputs] = useState({
    username: '',
    token_name: '',
    model_name: '',
    start_timestamp: timestamp2string(0),
    end_timestamp: timestamp2string(now.getTime() / 1000 + 3600),
    channel: ''
  });
  const { username, token_name, model_name, start_timestamp, end_timestamp, channel } = inputs;

  const [stat, setStat] = useState({
    quota: 0,
    token: 0
  });

  const handleInputChange = (e, { name, value }) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const getLogSelfStat = async () => {
    let localStartTimestamp = Date.parse(start_timestamp) / 1000;
    let localEndTimestamp = Date.parse(end_timestamp) / 1000;
    let res = await API.get(`/api/log/self/stat?type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`);
    const { success, message, data } = res.data;
    if (success) {
      setStat(data);
    } else {
      showError(message);
    }
  };

  const getLogStat = async () => {
    let localStartTimestamp = Date.parse(start_timestamp) / 1000;
    let localEndTimestamp = Date.parse(end_timestamp) / 1000;
    let res = await API.get(`/api/log/stat?type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel=${channel}`);
    const { success, message, data } = res.data;
    if (success) {
      setStat(data);
    } else {
      showError(message);
    }
  };

  const handleEyeClick = async () => {
    if (!showStat) {
      if (isAdminUser) {
        await getLogStat();
      } else {
        await getLogSelfStat();
      }
    }
    setShowStat(!showStat);
  };

  const loadLogs = async (startIdx) => {
    let url = '';
    let localStartTimestamp = Date.parse(start_timestamp) / 1000;
    let localEndTimestamp = Date.parse(end_timestamp) / 1000;
    if (isAdminUser) {
      url = `/api/log/?p=${startIdx}&type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel=${channel}`;
    } else {
      url = `/api/log/self/?p=${startIdx}&type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`;
    }
    const res = await API.get(url);
    const { success, message, data } = res.data;
    if (success) {
      if (startIdx === 0) {
        setLogs(data);
      } else {
        let newLogs = [...logs];
        newLogs.splice(startIdx * ITEMS_PER_PAGE, data.length, ...data);
        setLogs(newLogs);
      }
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const onPaginationChange = (e, { activePage }) => {
    (async () => {
      if (activePage === Math.ceil(logs.length / ITEMS_PER_PAGE) + 1) {
        // In this case we have to load more data and then append them.
        await loadLogs(activePage - 1);
      }
      setActivePage(activePage);
    })();
  };

  const refresh = async () => {
    setLoading(true);
    setActivePage(1);
    await loadLogs(0);
  };

  useEffect(() => {
    refresh().then();
  }, [logType]);

  const searchLogs = async () => {
    if (searchKeyword === '') {
      // if keyword is blank, load files instead.
      await loadLogs(0);
      setActivePage(1);
      return;
    }
    setSearching(true);
    const res = await API.get(`/api/log/self/search?keyword=${searchKeyword}`);
    const { success, message, data } = res.data;
    if (success) {
      setLogs(data);
      setActivePage(1);
    } else {
      showError(message);
    }
    setSearching(false);
  };

  const handleKeywordChange = async (e, { value }) => {
    setSearchKeyword(value.trim());
  };

  const sortLog = (key) => {
    if (logs.length === 0) return;
    setLoading(true);
    let sortedLogs = [...logs];
    if (typeof sortedLogs[0][key] === 'string') {
      sortedLogs.sort((a, b) => {
        return ('' + a[key]).localeCompare(b[key]);
      });
    } else {
      sortedLogs.sort((a, b) => {
        if (a[key] === b[key]) return 0;
        if (a[key] > b[key]) return -1;
        if (a[key] < b[key]) return 1;
      });
    }
    if (sortedLogs[0].id === logs[0].id) {
      sortedLogs.reverse();
    }
    setLogs(sortedLogs);
    setLoading(false);
  };

  return (
    <>
      <Segment style={{
        background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.97) 0%, rgba(247, 249, 252, 0.97) 100%)',
        padding: '2em',
        borderRadius: '30px',
        boxShadow: '0 20px 40px rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        backdropFilter: 'blur(20px)',
      }}>
        <Header as='h3' style={{
          fontSize: '2em',
          fontWeight: '800',
          marginBottom: '1em',
          background: 'linear-gradient(135deg, #2c3e50 0%, #3498db 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}>
          {t.title}
          <span style={{ marginLeft: '15px', fontSize: '0.8em', fontWeight: 'normal' }}>
            （{t.totalQuota}：
            {showStat && renderQuota(stat.quota)}
            {!showStat && 
              <span 
                onClick={handleEyeClick} 
                style={{ 
                  cursor: 'pointer', 
                  color: '#3498db',
                  transition: 'color 0.3s ease'
                }}
                onMouseEnter={e => e.target.style.color = '#2980b9'}
                onMouseLeave={e => e.target.style.color = '#3498db'}
              >
                {t.clickToView}
              </span>
            }）
          </span>
        </Header>

        <Form style={{ marginBottom: '2em' }}>
          <Form.Group>
            <Form.Input
              fluid
              label={t.fields.apiKeyName}
              width={3}
              value={token_name}
              placeholder={t.fields.optional}
              name='token_name'
              onChange={handleInputChange}
              style={{
                '& input': {
                  borderRadius: '10px',
                  border: '2px solid rgba(52, 152, 219, 0.4)',
                }
              }}
            />
            <Form.Input
              fluid
              label={t.fields.modelName}
              width={3}
              value={model_name}
              placeholder={t.fields.optional}
              name='model_name'
              onChange={handleInputChange}
              style={{
                '& input': {
                  borderRadius: '10px',
                  border: '2px solid rgba(52, 152, 219, 0.4)',
                }
              }}
            />
            <Form.Input
              fluid
              label={t.fields.startTime}
              width={4}
              value={start_timestamp}
              type='datetime-local'
              name='start_timestamp'
              onChange={handleInputChange}
              style={{
                '& input': {
                  borderRadius: '10px',
                  border: '2px solid rgba(52, 152, 219, 0.4)',
                }
              }}
            />
            <Form.Input
              fluid
              label={t.fields.endTime}
              width={4}
              value={end_timestamp}
              type='datetime-local'
              name='end_timestamp'
              onChange={handleInputChange}
              style={{
                '& input': {
                  borderRadius: '10px',
                  border: '2px solid rgba(52, 152, 219, 0.4)',
                }
              }}
            />
            <Form.Button
              fluid
              label={t.actions}
              width={2}
              onClick={refresh}
              style={{
                background: 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)',
                color: 'white',
                borderRadius: '10px',
                transition: 'all 0.3s ease',
                boxShadow: '0 4px 15px rgba(52, 152, 219, 0.2)',
                border: 'none',
                height: '38px',
                marginTop: '23px'
              }}
              onMouseEnter={e => {
                e.currentTarget.style.transform = 'translateY(-2px)';
                e.currentTarget.style.boxShadow = '0 6px 20px rgba(52, 152, 219, 0.3)';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.transform = 'translateY(0)';
                e.currentTarget.style.boxShadow = '0 4px 15px rgba(52, 152, 219, 0.2)';
              }}
            >
              {t.query}
            </Form.Button>
          </Form.Group>
          {isAdminUser && (
            <Form.Group>
              <Form.Input
                fluid
                label={t.fields.channelId}
                width={3}
                value={channel}
                placeholder={t.fields.optional}
                name='channel'
                onChange={handleInputChange}
                style={{
                  '& input': {
                    borderRadius: '10px',
                    border: '2px solid rgba(52, 152, 219, 0.4)',
                  }
                }}
              />
              <Form.Input
                fluid
                label={t.fields.username}
                width={3}
                value={username}
                placeholder={t.fields.optional}
                name='username'
                onChange={handleInputChange}
                style={{
                  '& input': {
                    borderRadius: '10px',
                    border: '2px solid rgba(52, 152, 219, 0.4)',
                  }
                }}
              />
            </Form.Group>
          )}
        </Form>

        <div style={{
          background: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '20px',
          overflow: 'hidden',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)',
        }}>
          <Table basic compact size='small' style={{
            border: 'none',
            borderRadius: '20px',
            overflow: 'hidden',
          }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    background: '#f8f9fa',
                    padding: '1.2em',
                    fontWeight: '600',
                    color: '#2c3e50',
                    borderBottom: '2px solid rgba(52, 152, 219, 0.2)',
                    cursor: 'pointer',
                  }}
                  onClick={() => sortLog('created_time')}
                  width={3}
                >
                  {t.tableHeaders.time}
                </Table.HeaderCell>
                {
                  isAdminUser && <Table.HeaderCell
                    style={{
                      background: '#f8f9fa',
                      padding: '1.2em',
                      fontWeight: '600',
                      color: '#2c3e50',
                      borderBottom: '2px solid rgba(52, 152, 219, 0.2)',
                      cursor: 'pointer',
                    }}
                    onClick={() => sortLog('channel')}
                    width={1}
                  >
                    {t.tableHeaders.channel}
                  </Table.HeaderCell>
                }
                {
                  isAdminUser && <Table.HeaderCell
                    style={{
                      background: '#f8f9fa',
                      padding: '1.2em',
                      fontWeight: '600',
                      color: '#2c3e50',
                      borderBottom: '2px solid rgba(52, 152, 219, 0.2)',
                      cursor: 'pointer',
                    }}
                    onClick={() => sortLog('username')}
                    width={1}
                  >
                    {t.tableHeaders.user}
                  </Table.HeaderCell>
                }
                <Table.HeaderCell
                  style={{
                    background: '#f8f9fa',
                    padding: '1.2em',
                    fontWeight: '600',
                    color: '#2c3e50',
                    borderBottom: '2px solid rgba(52, 152, 219, 0.2)',
                    cursor: 'pointer',
                  }}
                  onClick={() => sortLog('token_name')}
                  width={1}
                >
                  {t.tableHeaders.apiKey}
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    background: '#f8f9fa',
                    padding: '1.2em',
                    fontWeight: '600',
                    color: '#2c3e50',
                    borderBottom: '2px solid rgba(52, 152, 219, 0.2)',
                    cursor: 'pointer',
                  }}
                  onClick={() => sortLog('type')}
                  width={1}
                >
                  {t.tableHeaders.type}
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    background: '#f8f9fa',
                    padding: '1.2em',
                    fontWeight: '600',
                    color: '#2c3e50',
                    borderBottom: '2px solid rgba(52, 152, 219, 0.2)',
                    cursor: 'pointer',
                  }}
                  onClick={() => sortLog('model_name')}
                  width={2}
                >
                  {t.tableHeaders.model}
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    background: '#f8f9fa',
                    padding: '1.2em',
                    fontWeight: '600',
                    color: '#2c3e50',
                    borderBottom: '2px solid rgba(52, 152, 219, 0.2)',
                    cursor: 'pointer',
                  }}
                  onClick={() => sortLog('prompt_tokens')}
                  width={1}
                >
                  {t.tableHeaders.inputTokens}
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    background: '#f8f9fa',
                    padding: '1.2em',
                    fontWeight: '600',
                    color: '#2c3e50',
                    borderBottom: '2px solid rgba(52, 152, 219, 0.2)',
                    cursor: 'pointer',
                  }}
                  onClick={() => sortLog('completion_tokens')}
                  width={1}
                >
                  {t.tableHeaders.outputTokens}
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    background: '#f8f9fa',
                    padding: '1.2em',
                    fontWeight: '600',
                    color: '#2c3e50',
                    borderBottom: '2px solid rgba(52, 152, 219, 0.2)',
                    cursor: 'pointer',
                  }}
                  onClick={() => sortLog('quota')}
                  width={1}
                >
                  {t.tableHeaders.quota}
                </Table.HeaderCell>
                {isAdminUser && (
                  <Table.HeaderCell
                    style={{
                      background: '#f8f9fa',
                      padding: '1.2em',
                      fontWeight: '600',
                      color: '#2c3e50',
                      borderBottom: '2px solid rgba(52, 152, 219, 0.2)',
                      cursor: 'pointer',
                    }}
                    onClick={() => sortLog('content')}
                    width={4}
                  >
                    {t.tableHeaders.details}
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {logs
                .slice((activePage - 1) * ITEMS_PER_PAGE, activePage * ITEMS_PER_PAGE)
                .map((log, idx) => (
                  <Table.Row key={log.id} style={{
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                      backgroundColor: 'rgba(52, 152, 219, 0.05)',
                    }
                  }}>
                    <Table.Cell>{renderTimestamp(log.created_at)}</Table.Cell>
                    {
                      isAdminUser && (
                        <Table.Cell>{log.channel ? <Label basic>{log.channel}</Label> : ''}</Table.Cell>
                      )
                    }
                    {
                      isAdminUser && (
                        <Table.Cell>{log.username ? <Label>{log.username}</Label> : ''}</Table.Cell>
                      )
                    }
                    <Table.Cell>{log.token_name ? <Label basic>{log.token_name}</Label> : ''}</Table.Cell>
                    <Table.Cell>{renderType(log.type)}</Table.Cell>
                    <Table.Cell>{log.model_name ? <Label basic>{log.model_name}</Label> : ''}</Table.Cell>
                    <Table.Cell>{log.prompt_tokens ? log.prompt_tokens : ''}</Table.Cell>
                    <Table.Cell>{log.completion_tokens ? log.completion_tokens : ''}</Table.Cell>
                    <Table.Cell>{log.quota ? renderQuota(log.quota, 6) : ''}</Table.Cell>
                    {isAdminUser && <Table.Cell>{log.content}</Table.Cell>}
                  </Table.Row>
                ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='10'>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <div>
                      <Select
                        placeholder={t.filterType}
                        options={LOG_OPTIONS}
                        style={{
                          marginRight: '8px',
                          minWidth: '150px',
                        }}
                        name='logType'
                        value={logType}
                        onChange={(e, { name, value }) => setLogType(value)}
                      />
                      <Button 
                        size='small' 
                        onClick={refresh} 
                        loading={loading}
                        style={{
                          background: 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)',
                          color: 'white',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease',
                          boxShadow: '0 4px 15px rgba(52, 152, 219, 0.2)',
                        }}
                      >
                        {t.refresh}
                      </Button>
                    </div>
                    <Pagination
                      floated='right'
                      activePage={activePage}
                      onPageChange={onPaginationChange}
                      size='small'
                      siblingRange={1}
                      totalPages={Math.ceil(logs.length / ITEMS_PER_PAGE) + (logs.length % ITEMS_PER_PAGE === 0 ? 1 : 0)}
                      style={{
                        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.05)',
                        borderRadius: '30px',
                        padding: '10px',
                        background: 'white',
                      }}
                    />
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </Segment>
    </>
  );
};

export default LogsTable;