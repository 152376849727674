import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/User';

import { Button, Container, Dropdown, Icon, Menu, Segment } from 'semantic-ui-react';
import { API, getLogo, getSystemName, isAdmin, isMobile } from '../helpers';
import { getUserLanguage, setUserLanguage, translations } from '../utils/language';
import '../index.css';

// 初始化翻譯對象
const t = translations[getUserLanguage()];

const Header = () => {
  const [userState, userDispatch] = useContext(UserContext);
  const [currentLanguage, setCurrentLanguage] = useState(getUserLanguage());
  const [t, setT] = useState(translations[currentLanguage]);
  let navigate = useNavigate();
  let location = useLocation();

  const getFirstAvailableToken = async () => {
    try {
      const res = await API.get('/api/token/');
      const { success, data } = res.data;
      if (success && data && data.length > 0) {
        // 找到第一個狀態為啟用的 token
        const availableToken = data.find(token => token.status === 1 && !token.deleted);
        if (availableToken) {
          let status = localStorage.getItem('status');
          let serverAddress = '';
          if (status) {
            status = JSON.parse(status);
            serverAddress = status.server_address;
          }
          if (serverAddress === '') {
            serverAddress = window.location.origin;
          }
          const nextLink = localStorage.getItem('chat_link');
          let nextUrl;
          if (nextLink) {
            nextUrl = nextLink + `/#/?settings={"key":"sk-${availableToken.key}","url":"${serverAddress}"}`;
          } else {
            nextUrl = `https://chat.stima.tech/#/?settings={"key":"sk-${availableToken.key}","url":"${serverAddress}"}`;
          }
          return { url: nextUrl, hasToken: true };
        }
      }
      return { url: null, hasToken: false };
    } catch (error) {
      console.error('Error fetching tokens:', error);
      return { url: null, hasToken: false };
    }
  };

  // Header Buttons
  const headerButtons = [
    // 基礎功能（不需登入）
    {
      name: t.navigation.chat,
      to: 'https://chat.stima.tech',
      icon: 'comments',
      external: true,
      onClick: async (navigate) => {
        if (!userState.user) {
          // 儲存當前的意圖，用於登入後的處理
          localStorage.setItem('post_login_action', 'chat');
          navigate('/login');
          return false;
        }
        const { url, hasToken } = await getFirstAvailableToken();
        if (url) {
          window.open(url, '_blank');
        }
        // 無論是否有可用的 token，都導向到 token 頁面
        navigate('/token');
        return false;
      }
    },
    {
      name: t.navigation.guide,
      to: 'https://docs.stima.tech',
      icon: 'book',
      external: true 
    },
    {
      name: t.navigation.purchase,
      to: 'https://payment.stima.tech',
      icon: 'credit card',
      external: true
    },
    {
      name: t.navigation.modelList,
      to: 'https://api.stima.tech/#pricing',
      icon: 'cubes',
      external: true,
      currentTab: true
    },
    // 需要登入的核心功能
    {
      name: t.navigation.apiKey,
      to: '/token',
      icon: 'key',
      requireLogin: true
    },
    {
      name: t.navigation.topup,
      to: '/topup',
      icon: 'plus',
      requireLogin: true
    },
    {
      name: t.navigation.log,
      to: '/log',
      icon: 'history',
      requireLogin: true
    },
    {
      name: t.navigation.settings,
      to: '/setting',
      icon: 'cog',
      requireLogin: true
    },
    // 管理員功能
    {
      name: t.navigation.channel,
      to: '/channel',
      icon: 'sitemap',
      admin: true
    },
    {
      name: t.navigation.redemption,
      to: '/redemption',
      icon: 'dollar sign',
      admin: true
    },
    {
      name: t.navigation.user,
      to: '/user',
      icon: 'users',
      admin: true
    }
  ];

  const [showSidebar, setShowSidebar] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const systemName = getSystemName();
  const logo = getLogo();

  const headerStyle = {
    background: 'linear-gradient(to right, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95))',
    backdropFilter: 'blur(20px) saturate(180%)',
    WebkitBackdropFilter: 'blur(20px) saturate(180%)',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.04), 0 2px 8px rgba(52, 152, 219, 0.06)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
    padding: '8px 0',
    height: '68px',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  };

  const headerBeforeStyle = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at 50% -20%, rgba(52, 152, 219, 0.08), transparent 70%), linear-gradient(45deg, rgba(52, 152, 219, 0.03) 0%, transparent 70%), linear-gradient(-45deg, rgba(41, 128, 185, 0.02) 0%, transparent 70%)',
    pointerEvents: 'none',
  };

  const logoStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.35em',
    fontWeight: '700',
    background: 'linear-gradient(135deg, #2c3e50 0%, #3498db 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    letterSpacing: '0.6px',
    padding: '0 0.9em',
    height: '100%',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    position: 'relative',
    zIndex: 2,
    '&:hover': {
      transform: 'translateY(-1px) scale(1.02)',
      filter: 'brightness(1.1) contrast(1.05)',
    }
  };

  const logoImageStyle = {
    width: 'auto',
    height: '35px',
    marginRight: '0.6em',
    objectFit: 'contain',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1))',
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.15))',
    }
  };

  const getMenuItemStyle = (isActive, isHovered) => ({
    color: isActive ? '#3498db' : isHovered ? '#3498db' : '#2c3e50',
    padding: '0.7em 1.2em',
    fontSize: '0.96em',
    fontWeight: isActive ? '600' : '500',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    cursor: 'pointer',
    position: 'relative',
    letterSpacing: '0.4px',
    marginLeft: '0.7em',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: isActive 
      ? 'linear-gradient(145deg, rgba(52, 152, 219, 0.12), rgba(52, 152, 219, 0.06))' 
      : isHovered 
        ? 'linear-gradient(145deg, rgba(52, 152, 219, 0.08), rgba(52, 152, 219, 0.04))' 
        : 'transparent',
    borderRadius: '14px',
    border: isActive ? '1px solid rgba(52, 152, 219, 0.25)' : '1px solid transparent',
    backdropFilter: isHovered ? 'blur(12px)' : 'none',
    transform: isHovered ? 'translateY(-2px)' : 'translateY(0)',
    boxShadow: isHovered 
      ? '0 8px 24px rgba(52, 152, 219, 0.15), 0 2px 8px rgba(52, 152, 219, 0.1)' 
      : 'none',
  });

  const getMenuItemContentStyle = (isActive, isHovered) => ({
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    transform: isHovered ? 'translateY(-1px)' : 'translateY(0)',
    transition: 'all 0.3s ease',
  });

  const getMenuItemBackgroundStyle = (isActive, isHovered) => ({
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: isHovered 
      ? 'linear-gradient(145deg, rgba(37, 99, 235, 0.08), rgba(37, 99, 235, 0.04))' 
      : 'transparent',
    borderRadius: '10px',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    zIndex: 0,
    boxShadow: isHovered 
      ? '0 4px 12px rgba(37, 99, 235, 0.08), inset 0 0 0 1px rgba(37, 99, 235, 0.08)' 
      : 'none',
    opacity: isActive ? 0 : 1,
  });

  const getMenuItemIndicatorStyle = (isActive, isHovered) => ({
    position: 'absolute',
    bottom: '-1px',
    left: '50%',
    width: '60%',
    height: '2px',
    background: 'linear-gradient(90deg, #2563eb, #3b82f6)',
    transform: `translateX(-50%) scaleX(${isHovered || isActive ? 1 : 0})`,
    transformOrigin: 'center',
    transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    zIndex: 1,
    opacity: 0.9,
    borderRadius: '2px',
  });

  const loginButtonStyle = (isActive, isHovered) => ({
    background: isHovered 
      ? 'linear-gradient(145deg, #3498db, #2980b9)' 
      : 'linear-gradient(145deg, #2980b9, #2472a4)',
    color: 'white',
    padding: '0.8em 2em',
    fontSize: '0.96em',
    fontWeight: '600',
    borderRadius: '14px',
    border: 'none',
    boxShadow: isHovered 
      ? '0 12px 28px rgba(52, 152, 219, 0.3), inset 0 1px 0 rgba(255,255,255,0.2), 0 1px 3px rgba(52, 152, 219, 0.2)' 
      : '0 8px 20px rgba(52, 152, 219, 0.2), inset 0 1px 0 rgba(255,255,255,0.1)',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    letterSpacing: '0.6px',
    marginLeft: '0.6em',
    transform: isHovered ? 'translateY(-2px) scale(1.02)' : 'translateY(0)',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.15), transparent)',
      transform: isHovered ? 'translateX(100%)' : 'translateX(-100%)',
      transition: 'transform 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
    }
  });

  const getIconStyle = (isActive, isHovered) => ({
    marginRight: '8px',
    fontSize: '1.25em',
    color: isActive ? '#3498db' : isHovered ? '#3498db' : '#34495e',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    transform: isHovered ? 'translateY(-1px) scale(1.15)' : 'translateY(0) scale(1)',
    filter: isActive 
      ? 'drop-shadow(0 4px 8px rgba(52, 152, 219, 0.3))' 
      : isHovered 
        ? 'drop-shadow(0 3px 6px rgba(52, 152, 219, 0.2))' 
        : 'none',
  });

  async function logout() {
    setShowSidebar(false);
    await API.get('/api/user/logout');
    // showSuccess('登出成功!');
    userDispatch({ type: 'logout' });
    localStorage.removeItem('user');
    navigate('/login');
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const renderButtons = (isMobile) => {
    return headerButtons.map((button, index) => {
      if (button.admin && !isAdmin()) return null;
      if (button.requireLogin && !userState.user) return null;
      const isActive = location.pathname === button.to;
      const isHovered = hoveredItem === button.name;
      
      if (isMobile) {
        return (
          <Menu.Item
            key={button.name || index}
            onClick={async () => {
              if (button.onClick) {
                const shouldContinue = await button.onClick(navigate);
                if (!shouldContinue) {
                  return;
                }
              }
              if (button.external) {
                if (button.currentTab) {
                  window.location.href = button.to;
                } else {
                  window.open(button.to, '_blank', 'noopener,noreferrer');
                }
              } else {
                navigate(button.to);
              }
              setShowSidebar(false);
            }}
            style={getMenuItemStyle(isActive, isHovered)}
          >
            <div style={getMenuItemContentStyle(isActive, isHovered)}>
              <Icon 
                name={button.icon} 
                style={getIconStyle(isActive, isHovered)} 
              />
              <span style={{
                transform: isHovered ? 'translateY(-1px)' : 'translateY(0)',
                transition: 'all 0.2s ease',
              }}>
                {button.name}
              </span>
            </div>
            <div style={getMenuItemBackgroundStyle(isActive, isHovered)} />
            <div style={getMenuItemIndicatorStyle(isActive, isHovered)} />
          </Menu.Item>
        );
      }
      return (
        <Menu.Item 
          key={button.name} 
          as={button.external ? 'a' : Link}
          to={button.external ? undefined : button.to}
          href={button.external ? button.to : undefined}
          target={button.external && !button.currentTab ? '_blank' : undefined}
          rel={button.external && !button.currentTab ? 'noopener noreferrer' : undefined}
          onClick={async (e) => {
            e.preventDefault();
            if (button.onClick) {
              const shouldContinue = await button.onClick(navigate);
              if (!shouldContinue) {
                return;
              }
            }
            if (button.external) {
              if (button.currentTab) {
                window.location.href = button.to;
              } else {
                window.open(button.to, '_blank', 'noopener,noreferrer');
              }
            } else {
              navigate(button.to);
            }
          }}
          style={getMenuItemStyle(isActive, isHovered)}
          onMouseEnter={() => setHoveredItem(button.name)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div style={getMenuItemContentStyle(isActive, isHovered)}>
            <Icon 
              name={button.icon} 
              style={getIconStyle(isActive, isHovered)} 
            />
            <span style={{
              transform: isHovered ? 'translateY(-1px)' : 'translateY(0)',
              transition: 'all 0.2s ease',
            }}>
              {button.name}
            </span>
          </div>
          <div style={getMenuItemBackgroundStyle(isActive, isHovered)} />
          <div style={getMenuItemIndicatorStyle(isActive, isHovered)} />
        </Menu.Item>
      );
    }).filter(Boolean);
  };

  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showSidebar]);

  useEffect(() => {
    setT(translations[currentLanguage]);
  }, [currentLanguage]);

  useEffect(() => {
    // 檢查是否有登入後需要執行的動作
    const postLoginAction = localStorage.getItem('post_login_action');
    if (postLoginAction === 'chat' && userState.user) {
      localStorage.removeItem('post_login_action');
      getFirstAvailableToken().then(({ url }) => {
        if (url) {
          window.open(url, '_blank');
        }
      });
    }
  }, [userState.user]);

  if (isMobile()) {
    return (
      <>
        <Menu
          borderless
          size='large'
          style={{
            ...headerStyle,
            marginBottom: showSidebar ? '0' : undefined,
          }}
        >
          <div style={headerBeforeStyle} />
          <Container style={{height: '100%', position: 'relative', zIndex: 1}}>
            <Menu.Item as={Link} to='/' style={{...logoStyle, height: '100%'}}>
              <img
                src={logo}
                alt='logo'
                style={{
                  ...logoImageStyle,
                  height: '30px',
                  marginRight: '0.4em'
                }}
              />
              <div style={{
                fontSize: '1.1em',
                background: 'linear-gradient(135deg, #2c3e50 0%, #3498db 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: '600'
              }}>{systemName}</div>
            </Menu.Item>
            <Menu.Menu position='right'>
              <Menu.Item onClick={toggleSidebar} style={{
                padding: '0.5em',
                marginLeft: 'auto'
              }}>
                <Icon 
                  name={showSidebar ? 'close' : 'sidebar'} 
                  size='large' 
                  style={{
                    color: '#34495e',
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    transform: showSidebar ? 'rotate(90deg)' : 'none'
                  }} 
                />
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>
        {showSidebar && (
          <Segment style={{ 
            marginTop: '60px',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            overflow: 'auto',
            background: '#f8f9fa',
            padding: 0,
            border: 'none',
            boxShadow: 'none'
          }}>
            <Menu 
              secondary 
              vertical 
              style={{ 
                width: '100%', 
                margin: 0,
                borderRadius: 0,
                height: 'auto',
                minHeight: '100%',
                background: '#ffffff',
                boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
              }}
            >
              {renderButtons(true)}
              <Menu.Item style={{ padding: '15px', background: 'transparent' }}>
                <div style={{ 
                  display: 'flex', 
                  gap: '12px',
                  width: '100%',
                  marginBottom: '15px'
                }}>
                  <Button
                    fluid
                    onClick={() => {
                      setUserLanguage('zh-TW');
                      setCurrentLanguage('zh-TW');
                      window.location.reload();
                    }}
                    style={{
                      flex: 1,
                      background: currentLanguage === 'zh-TW' ? 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)' : 'white',
                      color: currentLanguage === 'zh-TW' ? 'white' : '#3498db',
                      padding: '12px 24px',
                      fontSize: '0.95em',
                      fontWeight: '600',
                      borderRadius: '8px',
                      border: currentLanguage === 'zh-TW' ? 'none' : '2px solid #3498db',
                      boxShadow: currentLanguage === 'zh-TW' ? '0 4px 12px rgba(52, 152, 219, 0.15)' : '0 4px 12px rgba(52, 152, 219, 0.1)',
                      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                      letterSpacing: '0.5px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                  >
                    繁體中文
                  </Button>
                  <Button
                    fluid
                    onClick={() => {
                      setUserLanguage('en');
                      setCurrentLanguage('en');
                      window.location.reload();
                    }}
                    style={{
                      flex: 1,
                      background: currentLanguage === 'en' ? 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)' : 'white',
                      color: currentLanguage === 'en' ? 'white' : '#3498db',
                      padding: '12px 24px',
                      fontSize: '0.95em',
                      fontWeight: '600',
                      borderRadius: '8px',
                      border: currentLanguage === 'en' ? 'none' : '2px solid #3498db',
                      boxShadow: currentLanguage === 'en' ? '0 4px 12px rgba(52, 152, 219, 0.15)' : '0 4px 12px rgba(52, 152, 219, 0.1)',
                      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                      letterSpacing: '0.5px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                  >
                    English
                  </Button>
                </div>
                {userState.user ? (
                  <Button 
                    fluid 
                    onClick={logout} 
                    style={{
                      background: 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)',
                      color: 'white',
                      padding: '12px 24px',
                      fontSize: '0.95em',
                      fontWeight: '600',
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: '0 4px 12px rgba(52, 152, 219, 0.15)',
                      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                      letterSpacing: '0.5px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                  >
                    {t.auth.logout}
                  </Button>
                ) : (
                  <div style={{ 
                    display: 'flex', 
                    gap: '12px',
                    width: '100%'
                  }}>
                    <Button
                      fluid
                      onClick={() => {
                        setShowSidebar(false);
                        navigate('/login');
                      }}
                      style={{
                        flex: 1,
                        background: 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)',
                        color: 'white',
                        padding: '12px 24px',
                        fontSize: '0.95em',
                        fontWeight: '600',
                        borderRadius: '8px',
                        border: 'none',
                        boxShadow: '0 4px 12px rgba(52, 152, 219, 0.15)',
                        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                        letterSpacing: '0.5px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        overflow: 'hidden'
                      }}
                    >
                      {t.auth.login}
                    </Button>
                    <Button
                      fluid
                      onClick={() => {
                        setShowSidebar(false);
                        navigate('/register');
                      }}
                      style={{
                        flex: 1,
                        background: 'white',
                        color: '#3498db',
                        padding: '12px 24px',
                        fontSize: '0.95em',
                        fontWeight: '600',
                        borderRadius: '8px',
                        border: '2px solid #3498db',
                        boxShadow: '0 4px 12px rgba(52, 152, 219, 0.1)',
                        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                        letterSpacing: '0.5px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        overflow: 'hidden'
                      }}
                    >
                      {t.auth.register}
                    </Button>
                  </div>
                )}
              </Menu.Item>
            </Menu>
          </Segment>
        )}
      </>
    );
  }

  return (
    <Menu borderless style={headerStyle}>
      <div style={headerBeforeStyle} />
      <Container style={{height: '100%', position: 'relative', zIndex: 1}}>
        <Menu.Item as={Link} to='/' style={{...logoStyle, height: '100%'}}>
          <img src={logo} alt='logo' style={{
            ...logoImageStyle,
            height: '30px',
            marginRight: '0.4em'
          }} />
          <div style={{
            fontSize: '1.1em',
            background: 'linear-gradient(135deg, #2c3e50 0%, #3498db 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: '600'
          }}>{systemName}</div>
        </Menu.Item>
        {renderButtons(false)}
        <Menu.Menu position='right' style={{height: '100%'}}>
          <Dropdown
            item
            icon={null}
            open={hoveredItem === 'language-dropdown'}
            onOpen={() => setHoveredItem('language-dropdown')}
            onClose={() => setHoveredItem(null)}
            trigger={
              <div style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                borderRadius: '8px',
                background: hoveredItem === 'language-dropdown' ? 'rgba(52, 152, 219, 0.1)' : 'transparent',
                transition: 'all 0.2s ease',
                cursor: 'pointer',
              }}>
                <Icon 
                  name="globe"
                  style={{
                    margin: 0,
                    color: '#3498db',
                    fontSize: '1.4em',
                    transform: hoveredItem === 'language-dropdown' ? 'scale(1.1) rotate(20deg)' : 'scale(1) rotate(0deg)',
                    transition: 'all 0.3s ease',
                  }}
                />
              </div>
            }
            style={{
              marginRight: '6px',
            }}
            onMouseEnter={() => setHoveredItem('language-dropdown')}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <Dropdown.Menu style={{
              borderRadius: '10px',
              border: '1px solid rgba(52, 152, 219, 0.1)',
              boxShadow: '0 4px 20px rgba(52, 152, 219, 0.1)',
              overflow: 'hidden',
              minWidth: '130px',
            }}>
              <Dropdown.Item 
                onClick={() => {
                  setUserLanguage('zh-TW');
                  setCurrentLanguage('zh-TW');
                  window.location.reload();
                }}
                active={currentLanguage === 'zh-TW'}
                style={{
                  color: currentLanguage === 'zh-TW' ? '#3498db' : '#2c3e50',
                  background: currentLanguage === 'zh-TW' ? 'rgba(52, 152, 219, 0.1)' : 'transparent',
                  fontSize: '0.95em',
                  padding: '0.8em 1.2em',
                  transition: 'all 0.2s ease',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon name="globe" style={{
                  marginRight: '8px',
                  color: currentLanguage === 'zh-TW' ? '#3498db' : '#34495e',
                  fontSize: '1.2em',
                }}/>
                繁體中文
                <i className="tw flag" style={{
                  marginLeft: '8px',
                  opacity: currentLanguage === 'zh-TW' ? 1 : 0.7,
                  transition: 'opacity 0.2s ease',
                }}></i>
              </Dropdown.Item>
              <Dropdown.Item 
                onClick={() => {
                  setUserLanguage('en');
                  setCurrentLanguage('en');
                  window.location.reload();
                }}
                active={currentLanguage === 'en'}
                style={{
                  color: currentLanguage === 'en' ? '#3498db' : '#2c3e50',
                  background: currentLanguage === 'en' ? 'rgba(52, 152, 219, 0.1)' : 'transparent',
                  fontSize: '0.95em',
                  padding: '0.8em 1.2em',
                  transition: 'all 0.2s ease',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon name="globe" style={{
                  marginRight: '8px',
                  color: currentLanguage === 'en' ? '#3498db' : '#34495e',
                  fontSize: '1.2em',
                }}/>
                English
                <i className="us flag" style={{
                  marginLeft: '8px',
                  opacity: currentLanguage === 'en' ? 1 : 0.7,
                  transition: 'opacity 0.2s ease',
                }}></i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {userState.user ? (
            <Dropdown
              text={userState.user.username}
              pointing
              className='link item'
              style={getMenuItemStyle(false, hoveredItem === 'user-dropdown')}
              onMouseEnter={() => setHoveredItem('user-dropdown')}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <Dropdown.Menu>
                <Dropdown.Item 
                  onClick={logout}
                  style={{
                    color: '#2c3e50',
                    fontSize: '0.95em',
                    padding: '0.8em 1.2em',
                  }}
                >
                  {t.auth.logout}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Menu.Item
              name='登入'
              as={Link}
              to='/login'
              style={loginButtonStyle(location.pathname === '/login', hoveredItem === 'login')}
              onMouseEnter={() => setHoveredItem('login')}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <Icon name='sign in' style={{ marginRight: '8px' }} />
              {t.auth.login}
            </Menu.Item>
          )}
        </Menu.Menu>
      </Container>
    </Menu>
  );
};

export default Header;
