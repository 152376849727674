import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { API, getLogo, showError, showInfo, showSuccess } from '../helpers';
import Turnstile from 'react-turnstile';
import styled from 'styled-components';
import { getUserLanguage, translations } from '../utils/language';

const PageContainer = styled.div`
  padding-top: 80px;
  min-height: 100vh;
  background: linear-gradient(135deg, 
    rgba(255, 255, 255, 0.97) 0%,
    rgba(247, 249, 252, 0.97) 100%
  );
  position: relative;
  overflow: hidden;
`;

const DynamicBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 20% 30%, rgba(74, 144, 226, 0.08) 0%, transparent 50%),
              radial-gradient(circle at 80% 70%, rgba(52, 152, 219, 0.08) 0%, transparent 50%),
              radial-gradient(circle at 50% 50%, rgba(41, 128, 185, 0.05) 0%, transparent 70%);
  animation: pulse 8s ease-in-out infinite alternate;

  @keyframes pulse {
    0% { opacity: 0.3; }
    50% { opacity: 0.5; }
    100% { opacity: 0.3; }
  }
`;

const FloatingShape1 = styled.div`
  position: absolute;
  top: 10%;
  right: 15%;
  width: 200px;
  height: 200px;
  background: linear-gradient(135deg, rgba(74, 144, 226, 0.2) 0%, rgba(52, 152, 219, 0.2) 100%);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: morphing 15s ease-in-out infinite;
  opacity: 0.7;

  @keyframes morphing {
    0% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
    25% { border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%; }
    50% { border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%; }
    75% { border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%; }
    100% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
  }
`;

const FloatingShape2 = styled.div`
  position: absolute;
  bottom: 15%;
  left: 10%;
  width: 150px;
  height: 150px;
  background: linear-gradient(135deg, rgba(52, 152, 219, 0.2) 0%, rgba(41, 128, 185, 0.2) 100%);
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  animation: morphing 12s ease-in-out infinite reverse;
  opacity: 0.5;
`;

const ContentWrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding: 0 20px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 48px !important;
  background: linear-gradient(135deg, 
    rgba(255, 255, 255, 0.97) 0%,
    rgba(247, 249, 252, 0.97) 100%
  ) !important;
  min-height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  padding: 2em !important;
`;

const StyledColumn = styled(Grid.Column)`
  max-width: 450px;
`;

const StyledSegment = styled(Segment)`
  background: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(20px) !important;
  padding: 2em !important;
  border-radius: 30px !important;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
`;

const StyledHeader = styled(Header)`
  font-size: 2.5em !important;
  font-weight: 800 !important;
  margin-bottom: 1em !important;
  background: linear-gradient(135deg, #2c3e50 0%, #3498db 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  text-align: center;
`;

const StyledLogo = styled(Image)`
  width: 120px !important;
  height: auto !important;
  margin: 0 auto 1em auto !important;
  display: block !important;
`;

const StyledFormInput = styled(Form.Input)`
  input {
    border-radius: 50px !important;
    padding: 15px 25px !important;
    border: 2px solid rgba(52, 152, 219, 0.3) !important;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
    font-size: 1.1em !important;
    transition: all 0.3s ease !important;

    &:focus {
      border-color: #3498db !important;
      box-shadow: 0 6px 20px rgba(52, 152, 219, 0.15) !important;
    }
  }
`;

const StyledButton = styled(Button)`
  background: linear-gradient(135deg, #3498db 0%, #2980b9 100%) !important;
  color: white !important;
  padding: 1.2em !important;
  font-size: 1.1em !important;
  font-weight: 600 !important;
  border-radius: 50px !important;
  border: none !important;
  box-shadow: 0 10px 20px rgba(52, 152, 219, 0.3) !important;
  transition: all 0.3s ease !important;

  &:hover:not(:disabled) {
    transform: translateY(-3px) scale(1.05) !important;
    box-shadow: 0 15px 30px rgba(52, 152, 219, 0.4) !important;
  }

  &:active:not(:disabled) {
    transform: translateY(1px) !important;
  }

  &:disabled {
    opacity: 0.7 !important;
    cursor: not-allowed !important;
  }
`;

const StyledMessage = styled(Message)`
  border-radius: 15px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
  background: rgba(255, 255, 255, 0.9) !important;
`;

const LoginMessage = styled(Message)`
  background: rgba(255, 255, 255, 0.9) !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
  padding: 1.5em !important;
  text-align: center !important;
  font-size: 1.1em !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.5em !important;
  transition: all 0.3s ease !important;

  &:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08) !important;
  }
`;

const StyledLink = styled(Link)`
  color: #3498db !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  padding: 0.5em 1em !important;
  border-radius: 25px !important;
  background: rgba(52, 152, 219, 0.1) !important;
  transition: all 0.3s ease !important;

  &:hover {
    background: rgba(52, 152, 219, 0.2) !important;
    transform: translateY(-1px) !important;
  }

  &:active {
    transform: translateY(1px) !important;
  }
`;

const PasswordInputWrapper = styled.div`
  position: relative;
  margin-bottom: 1em;
`;

const PasswordToggleIcon = styled.i`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #7f8c8d;
  transition: color 0.3s ease;
  z-index: 3;
  
  &:hover {
    color: #34495e;
  }
`;

const RegisterForm = () => {
  const currentLanguage = getUserLanguage();
  const t = translations[currentLanguage].register;

  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    password2: '',
    email: '',
    verification_code: ''
  });
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const { username, password, password2 } = inputs;
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [turnstileEnabled, setTurnstileEnabled] = useState(false);
  const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [loading, setLoading] = useState(false);
  const logo = getLogo();
  let affCode = new URLSearchParams(window.location.search).get('aff');
  if (affCode) {
    localStorage.setItem('aff', affCode);
  }

  const [verificationSent, setVerificationSent] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      setShowEmailVerification(status.email_verification);
      if (status.turnstile_check) {
        setTurnstileEnabled(true);
        setTurnstileSiteKey(status.turnstile_site_key);
      }
    }
  });

  let navigate = useNavigate();

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(prevInputs => {
      const newInputs = { ...prevInputs, [name]: value };
      
      if (name === 'password' || name === 'password2') {
        const isValid = newInputs.password.length >= 8 && newInputs.password.length <= 20;
        setPasswordValid(isValid);
        setPasswordMatch(newInputs.password === newInputs.password2);
      }
      
      return newInputs;
    });
  }

  async function handleSubmit(e) {
    if (password.length < 8) {
      showInfo(t.messages.passwordLengthError);
      return;
    }
    if (password !== password2) {
      showInfo(t.messages.passwordMismatch);
      return;
    }
    if (username && password) {
      if (turnstileEnabled && turnstileToken === '') {
        showInfo(t.messages.turnstileWait);
        return;
      }
      setLoading(true);
      if (!affCode) {
        affCode = localStorage.getItem('aff');
      }
      inputs.aff_code = affCode;
      const res = await API.post(
        `/api/user/register?turnstile=${turnstileToken}`,
        inputs
      );
      const { success, message } = res.data;
      if (success) {
        navigate('/login');
        showSuccess(t.messages.registerSuccess);
      } else {
        showError(message);
      }
      setLoading(false);
    }
  }

  const sendVerificationCode = async () => {
    if (inputs.email === '') return;
    if (turnstileEnabled && turnstileToken === '') {
      showInfo(t.messages.turnstileWait);
      return;
    }
    setLoading(true);
    const res = await API.get(
      `/api/verification?email=${inputs.email}&turnstile=${turnstileToken}`
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess(t.messages.verificationCodeSent);
      setVerificationSent(true);
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const handleVerificationCodeFocus = () => {
    if (!verificationSent) {
      showInfo(t.messages.enterEmailFirst);
    }
  };

  return (
    <PageContainer>
      <DynamicBackground />
      <FloatingShape1 />
      <FloatingShape2 />
      <ContentWrapper>
        <LogoContainer>
          <StyledLogo src={logo} />
          <StyledHeader as='h2' textAlign='center'>
            {t.title}
          </StyledHeader>
        </LogoContainer>

        <StyledMessage warning>
          <span id="announcement-message">
            {t.announcement.content}
          </span>
        </StyledMessage>

        <Form size='large'>
          <StyledSegment>
            <StyledFormInput
              fluid
              icon='user'
              iconPosition='left'
              placeholder={t.fields.username.placeholder}
              onChange={handleChange}
              name='username'
            />
            <PasswordInputWrapper>
              <StyledFormInput
                fluid
                icon='lock'
                iconPosition='left'
                placeholder={t.fields.password.placeholder}
                onChange={handleChange}
                name='password'
                type={showPassword ? 'text' : 'password'}
                error={!passwordValid && { content: t.fields.password.error, pointing: 'below' }}
              />
              <PasswordToggleIcon
                className={`eye ${showPassword ? 'slash' : ''} icon`}
                onClick={() => setShowPassword(!showPassword)}
                title={showPassword ? t.fields.password.hide : t.fields.password.show}
              />
            </PasswordInputWrapper>
            <PasswordInputWrapper>
              <StyledFormInput
                fluid
                icon='lock'
                iconPosition='left'
                placeholder={t.fields.confirmPassword.placeholder}
                onChange={handleChange}
                name='password2'
                type={showPassword2 ? 'text' : 'password'}
                error={!passwordMatch && { content: t.fields.confirmPassword.error, pointing: 'below' }}
              />
              <PasswordToggleIcon
                className={`eye ${showPassword2 ? 'slash' : ''} icon`}
                onClick={() => setShowPassword2(!showPassword2)}
                title={showPassword2 ? t.fields.password.hide : t.fields.password.show}
              />
            </PasswordInputWrapper>
            <StyledFormInput
              fluid
              icon='mail'
              iconPosition='left'
              placeholder={t.fields.email.placeholder}
              onChange={handleChange}
              name='email'
              type='email'
              disabled={!passwordMatch || !passwordValid}
              action={
                <StyledButton color='teal' onClick={sendVerificationCode} disabled={loading || !passwordMatch || !passwordValid}>
                  {t.fields.verificationCode.button}
                </StyledButton>
              }
            />
            <StyledFormInput
              fluid
              icon='lock'
              iconPosition='left'
              placeholder={t.fields.verificationCode.placeholder}
              onChange={handleChange}
              name='verification_code'
              readOnly={!verificationSent}
              onFocus={handleVerificationCodeFocus}
            />
            {turnstileEnabled && (
              <div style={{ margin: '1em 0' }}>
                <Turnstile
                  sitekey={turnstileSiteKey}
                  onVerify={(token) => {
                    setTurnstileToken(token);
                  }}
                />
              </div>
            )}
            <StyledButton
              fluid
              size='large'
              onClick={handleSubmit}
              loading={loading}
            >
              {t.buttons.register}
            </StyledButton>
          </StyledSegment>
        </Form>

        <LoginMessage>
          {t.loginPrompt}
          <StyledLink to='/login'>
            {t.buttons.login}
          </StyledLink>
        </LoginMessage>
      </ContentWrapper>
    </PageContainer>
  );
};

export default RegisterForm;