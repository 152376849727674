import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Label, Pagination, Popup, Table, Segment, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API, copy, showError, showSuccess, showWarning, timestamp2string } from '../helpers';
import { ITEMS_PER_PAGE } from '../constants';
import { renderQuota } from '../helpers/render';
import { getUserLanguage, translations } from '../utils/language';

function renderTimestamp(timestamp) {
  return (
    <>
      {timestamp2string(timestamp)}
    </>
  );
}

function renderStatus(status, t) {
  switch (status) {
    case 1:
      return <Label basic color='green'>{t.table.status.enabled}</Label>;
    case 2:
      return <Label basic color='red'>{t.table.status.disabled}</Label>;
    case 3:
      return <Label basic color='yellow'>{t.table.status.expired}</Label>;
    case 4:
      return <Label basic color='grey'>{t.table.status.exhausted}</Label>;
    default:
      return <Label basic color='black'>{t.table.status.unknown}</Label>;
  }
}

const TokensTable = () => {
  const currentLanguage = getUserLanguage();
  const t = translations[currentLanguage].tokensTable;

  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searching, setSearching] = useState(false);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [targetTokenIdx, setTargetTokenIdx] = useState(0);
  const [orderBy, setOrderBy] = useState('');

  const loadTokens = async (startIdx) => {
    const res = await API.get(`/api/token/?p=${startIdx}&order=${orderBy}`);
    const { success, message, data } = res.data;
    if (success) {
      if (startIdx === 0) {
        setTokens(data);
      } else {
        let newTokens = [...tokens];
        newTokens.splice(startIdx * ITEMS_PER_PAGE, data.length, ...data);
        setTokens(newTokens);
      }
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const onPaginationChange = (e, { activePage }) => {
    (async () => {
      if (activePage === Math.ceil(tokens.length / ITEMS_PER_PAGE) + 1) {
        // In this case we have to load more data and then append them.
        await loadTokens(activePage - 1, orderBy);
      }
      setActivePage(activePage);
    })();
  };

  const refresh = async () => {
    setLoading(true);
    await loadTokens(activePage - 1);
  };

  const onCopy = async (type, key) => {
    let status = localStorage.getItem('status');
    let serverAddress = '';
    if (status) {
      status = JSON.parse(status);
      serverAddress = status.server_address;
    }
    if (serverAddress === '') {
      serverAddress = window.location.origin;
    }
    let encodedServerAddress = encodeURIComponent(serverAddress);
    const nextLink = localStorage.getItem('chat_link');
    let nextUrl;
  
    if (nextLink) {
      nextUrl = nextLink + `/#/?settings={"key":"sk-${key}","url":"${serverAddress}`;
    } else {
      nextUrl = `https://chat.stima.tech/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
    }

    let url;
    switch (type) {
      case 'ama':
        url = `ama://set-api-key?server=${encodedServerAddress}&key=sk-${key}`;
        break;
      case 'opencat':
        url = `opencat://team/join?domain=${encodedServerAddress}&token=sk-${key}`;
        break;
      case 'next':
        url = nextUrl;
        break;
      default:
        url = `sk-${key}`;
    }
    if (await copy(url)) {
      showSuccess(t.messages.copySuccess);
    } else {
      showWarning(t.messages.copyError);
      setSearchKeyword(url);
    }
  };

  const onOpenLink = async (type, key) => {
    let status = localStorage.getItem('status');
    let serverAddress = '';
    if (status) {
      status = JSON.parse(status);
      serverAddress = status.server_address; 
    }
    if (serverAddress === '') {
      serverAddress = window.location.origin;
    }
    let encodedServerAddress = encodeURIComponent(serverAddress);
    const chatLink = localStorage.getItem('chat_link');
    const playgroundLink = localStorage.getItem('playground_link');
    let defaultUrl;
  
    if (type === 'playground') {
      if (playgroundLink) {
        defaultUrl = playgroundLink + `/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
      } else {
        defaultUrl = `https://playground.stima.tech/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
      }
    } else if (chatLink) {
      defaultUrl = chatLink + `/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
    } else {
      defaultUrl = `https://chat.stima.tech/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
    }
    let url;
    switch (type) {
      case 'ama':
        url = `ama://set-api-key?server=${encodedServerAddress}&key=sk-${key}`;
        break;
  
      case 'opencat':
        url = `opencat://team/join?domain=${encodedServerAddress}&token=sk-${key}`;
        break;
        
      case 'playground':
        url = defaultUrl;
        break;
        
      default:
        url = defaultUrl;
    }
  
    window.open(url, '_blank');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    loadTokens(0, orderBy)
      .then()
      .catch((reason) => {
        showError(reason);
      });
  }, [orderBy]);

  const manageToken = async (id, action, idx) => {
    let data = { id };
    let res;
    switch (action) {
      case 'delete':
        res = await API.delete(`/api/token/${id}/`);
        break;
      case 'enable':
        data.status = 1;
        res = await API.put('/api/token/?status_only=true', data);
        break;
      case 'disable':
        data.status = 2;
        res = await API.put('/api/token/?status_only=true', data);
        break;
    }
    const { success, message } = res.data;
    if (success) {
      showSuccess(t.messages.operationSuccess);
      let token = res.data.data;
      let newTokens = [...tokens];
      let realIdx = (activePage - 1) * ITEMS_PER_PAGE + idx;
      if (action === 'delete') {
        newTokens[realIdx].deleted = true;
      } else {
        newTokens[realIdx].status = token.status;
      }
      setTokens(newTokens);
    } else {
      showError(message);
    }
  };

  const searchTokens = async () => {
    if (searchKeyword === '') {
      // if keyword is blank, load files instead.
      await loadTokens(0);
      setActivePage(1);
      setOrderBy('');
      return;
    }
    setSearching(true);
    const res = await API.get(`/api/token/search?keyword=${searchKeyword}`);
    const { success, message, data } = res.data;
    if (success) {
      setTokens(data);
      setActivePage(1);
    } else {
      showError(message);
    }
    setSearching(false);
  };

  const handleKeywordChange = async (e, { value }) => {
    setSearchKeyword(value.trim());
  };

  const sortToken = (key) => {
    if (tokens.length === 0) return;
    setLoading(true);
    let sortedTokens = [...tokens];
    sortedTokens.sort((a, b) => {
      if (!isNaN(a[key])) {
        // If the value is numeric, subtract to sort
        return a[key] - b[key];
      } else {
        // If the value is not numeric, sort as strings
        return ('' + a[key]).localeCompare(b[key]);
      }
    });
    if (sortedTokens[0].id === tokens[0].id) {
      sortedTokens.reverse();
    }
    setTokens(sortedTokens);
    setLoading(false);
  };

  const handleOrderByChange = (e, { value }) => {
    setOrderBy(value);
    setActivePage(1);
  };

  const tableSegmentStyle = {
    background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%)',
    borderRadius: '15px',
    padding: '2em',
    boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
    marginTop: '2em'
  };

  const searchSegmentStyle = {
    background: 'white',
    borderRadius: '10px',
    padding: '1.5em',
    marginBottom: '1em',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
  };

  const buttonStyle = {
    borderRadius: '8px',
    transition: 'all 0.3s ease'
  };

  const tableStyle = {
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
  };

  const paginationStyle = {
    marginTop: '1em',
    '& .ui.pagination.menu': {
      border: 'none',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      borderRadius: '10px',
      background: 'transparent'
    },
    '& .item': {
      margin: '0 2px',
      borderRadius: '8px !important',
      minWidth: '35px',
      height: '35px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#666',
      border: '1px solid #eee',
      background: 'white',
      transition: 'all 0.3s ease',
      '&:hover': {
        background: '#f8f9fa',
        color: '#333'
      },
      '&.active': {
        background: '#2185d0',
        color: 'white',
        border: '1px solid #2185d0'
      }
    }
  };

  return (
    <>
      <Segment style={searchSegmentStyle}>
        <Header as='h2'>
          <Icon name='key' />
          <Header.Content>
            {t.header.title}
            <Header.Subheader>{t.header.subtitle}</Header.Subheader>
          </Header.Content>
        </Header>
        <Form onSubmit={searchTokens}>
          <Form.Input
            icon='search'
            fluid
            iconPosition='left'
            placeholder={t.header.search}
            value={searchKeyword}
            loading={searching}
            onChange={handleKeywordChange}
            style={{ marginTop: '1em' }}
          />
        </Form>
        <div style={{ marginTop: '1em', display: 'flex', gap: '2em' }}>
          <div>
            <Icon name='check circle' color='green' />
            {t.header.stats.enabled.replace('{count}', tokens.filter(token => token.status === 1 && !token.deleted).length)}
          </div>
          <div>
            <Icon name='infinity' color='blue' />
            {t.header.stats.neverExpire.replace('{count}', tokens.filter(token => token.expired_time === -1 && !token.deleted).length)}
          </div>
        </div>
      </Segment>

      <Segment style={tableSegmentStyle}>
        <Table basic='very' compact size='small' style={tableStyle}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ cursor: 'pointer', background: '#f8f9fa', padding: '1em', whiteSpace: 'nowrap', minWidth: '80px' }} onClick={() => { sortToken('name'); }}>
                <Icon name='file alternate outline' /> {t.table.headers.name}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ cursor: 'pointer', background: '#f8f9fa', padding: '1em', whiteSpace: 'nowrap', minWidth: '60px' }} onClick={() => { sortToken('status'); }}>
                <Icon name='info circle' /> {t.table.headers.status}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ cursor: 'pointer', background: '#f8f9fa', padding: '1em', whiteSpace: 'nowrap', minWidth: '80px' }} onClick={() => { sortToken('used_quota'); }}>
                <Icon name='chart bar' /> {t.table.headers.usedQuota}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ cursor: 'pointer', background: '#f8f9fa', padding: '1em', whiteSpace: 'nowrap', minWidth: '80px' }} onClick={() => { sortToken('remain_quota'); }}>
                <Icon name='chart pie' /> {t.table.headers.remainQuota}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ cursor: 'pointer', background: '#f8f9fa', padding: '1em', whiteSpace: 'nowrap', minWidth: '80px' }} onClick={() => { sortToken('created_time'); }}>
                <Icon name='calendar plus' /> {t.table.headers.createdTime}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ cursor: 'pointer', background: '#f8f9fa', padding: '1em', whiteSpace: 'nowrap', minWidth: '80px' }} onClick={() => { sortToken('expired_time'); }}>
                <Icon name='calendar times' /> {t.table.headers.expireTime}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: '#f8f9fa', padding: '1em', whiteSpace: 'nowrap', minWidth: '320px' }}>
                <Icon name='settings' /> {t.table.headers.actions}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {tokens
              .slice((activePage - 1) * ITEMS_PER_PAGE, activePage * ITEMS_PER_PAGE)
              .map((token, idx) => {
                if (token.deleted) return <></>;
                return (
                  <Table.Row key={token.id} style={{ transition: 'all 0.3s ease' }}>
                    <Table.Cell>{token.name ? token.name : t.table.values.noName}</Table.Cell>
                    <Table.Cell>{renderStatus(token.status, t)}</Table.Cell>
                    <Table.Cell>{renderQuota(token.used_quota)}</Table.Cell>
                    <Table.Cell>{token.unlimited_quota ? t.table.values.unlimited : renderQuota(token.remain_quota, 2)}</Table.Cell>
                    <Table.Cell>{renderTimestamp(token.created_time)}</Table.Cell>
                    <Table.Cell>{token.expired_time === -1 ? t.table.values.neverExpire : renderTimestamp(token.expired_time)}</Table.Cell>
                    <Table.Cell>
                      <Button.Group size='tiny' style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                        <Button
                          color='green'
                          icon='copy'
                          content={t.actions.copy}
                          style={{ ...buttonStyle, minWidth: '60px', margin: '0' }}
                          onClick={async () => {
                            await onCopy('', token.key);
                          }}
                        />
                        <Button
                          color='blue'
                          icon='chat'
                          content={t.actions.chat}
                          style={{ ...buttonStyle, minWidth: '80px', margin: '0' }}
                          onClick={() => {
                            onOpenLink('next', token.key);
                          }}
                        />
                        <Button
                          color='teal'
                          icon='code'
                          content={t.actions.playground}
                          style={{ ...buttonStyle, minWidth: '100px', margin: '0' }}
                          onClick={() => {
                            onOpenLink('playground', token.key);
                          }}
                        />
                        <Popup
                          trigger={
                            <Button
                              icon='trash'
                              color='red'
                              content={t.actions.delete}
                              style={{ ...buttonStyle, minWidth: '60px', margin: '0' }}
                            />
                          }
                          on='click'
                          flowing
                          hoverable
                        >
                          <Button
                            negative
                            onClick={() => {
                              manageToken(token.id, 'delete', idx);
                            }}
                          >
                            {t.actions.confirmDelete.replace('{name}', token.name)}
                          </Button>
                        </Popup>
                        <Button
                          icon={token.status === 1 ? 'pause' : 'play'}
                          content={token.status === 1 ? t.actions.disable : t.actions.enable}
                          color={token.status === 1 ? 'yellow' : 'olive'}
                          style={{ ...buttonStyle, minWidth: '60px', margin: '0' }}
                          onClick={() => {
                            manageToken(
                              token.id,
                              token.status === 1 ? 'disable' : 'enable',
                              idx
                            );
                          }}
                        />
                        <Button
                          icon='edit'
                          content={t.actions.edit}
                          style={{ ...buttonStyle, minWidth: '60px', margin: '0' }}
                          as={Link}
                          to={'/token/edit/' + token.id}
                        />
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='7'>
                <Button.Group>
                  <Button
                    primary
                    icon='plus'
                    content={t.buttons.add}
                    style={buttonStyle}
                    as={Link}
                    to='/token/add'
                    loading={loading}
                  />
                  <Button
                    icon='refresh'
                    content={t.buttons.refresh}
                    style={buttonStyle}
                    onClick={refresh}
                    loading={loading}
                  />
                </Button.Group>
                <Dropdown
                  placeholder={t.sorting.label}
                  selection
                  upward
                  options={[
                    { key: '', text: t.sorting.options.default, value: '' },
                    { key: 'remain_quota', text: t.sorting.options.remainQuota, value: 'remain_quota' },
                    { key: 'used_quota', text: t.sorting.options.usedQuota, value: 'used_quota' },
                  ]}
                  value={orderBy}
                  onChange={handleOrderByChange}
                  style={{ 
                    marginLeft: '10px',
                    position: 'relative',
                    zIndex: 1000
                  }}
                />
                <Pagination
                  floated='right'
                  activePage={activePage}
                  onPageChange={onPaginationChange}
                  size='mini'
                  siblingRange={1}
                  boundaryRange={1}
                  firstItem={{ content: '«', icon: true }}
                  lastItem={{ content: '»', icon: true }}
                  prevItem={{ content: '‹', icon: true }}
                  nextItem={{ content: '›', icon: true }}
                  style={paginationStyle}
                  totalPages={
                    Math.ceil(tokens.length / ITEMS_PER_PAGE) +
                    (tokens.length % ITEMS_PER_PAGE === 0 ? 1 : 0)
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
    </>
  );
};

export default TokensTable;