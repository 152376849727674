import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Segment, Statistic, Icon, Container, Divider, Message, Transition, Popup } from 'semantic-ui-react';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import { renderQuota } from '../../helpers/render';
import { getUserLanguage, translations } from '../../utils/language';

const TopUp = () => {
  const [redemptionCode, setRedemptionCode] = useState('');
  const [topUpLink, setTopUpLink] = useState('');
  const [userQuota, setUserQuota] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState({});
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const currentLanguage = getUserLanguage();
  const t = translations[currentLanguage].topup;

  const topUp = async () => {
    setError('');
    if (redemptionCode.trim() === '') {
      setError(t.quotaManagement.redemptionCode.invalidCode);
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await API.post('/api/user/topup', {
        key: redemptionCode
      });
      const { success, message, data } = res.data;
      if (success) {
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
        setUserQuota((quota) => quota + data);
        setRedemptionCode('');
      } else {
        setError(message);
      }
    } catch (err) {
      setError(t.quotaManagement.redemptionCode.requestFailed);
    } finally {
      setIsSubmitting(false);
    }
  };

  const openTopUpLink = () => {
    if (!topUpLink) {
      showError(t.quotaManagement.errors.noTopUpLink);
      return;
    }
    let url = new URL(topUpLink);
    let username = user.username;
    let user_id = user.id;
    // add  username and user_id to the topup link
    url.searchParams.append('username', username);
    url.searchParams.append('user_id', user_id);
    url.searchParams.append('transaction_id', crypto.randomUUID());
    window.open(url.toString(), '_blank');
  };

  const getUserQuota = async ()=>{
    let res  = await API.get(`/api/user/self`);
    const {success, message, data} = res.data;
    if (success) {
      setUserQuota(data.quota);
      setUser(data);
    } else {
      showError(message);
    }
  }

  const handleTopUp = () => {
    if (!redemptionCode.trim()) {
      setError(t.quotaManagement.redemptionCode.invalidCode);
      return;
    }
    topUp();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      if (status.top_up_link) {
        setTopUpLink(status.top_up_link);
      }
    }
    getUserQuota().then();
  }, []);

  return (
    <Container>
      <Segment raised padded="very" className="modern-card">
        <Header as='h2' textAlign='center' style={{ marginBottom: '2.5em' }}>
          <Icon name='dollar' circular size='large' className="gradient-icon" />
          <Header.Content>
            {t.title}
            <Header.Subheader style={{ marginTop: '0.8em', fontSize: '1.1em', color: '#666' }}>
              {t.subtitle}
            </Header.Subheader>
          </Header.Content>
        </Header>

        <Grid columns={2} stackable divided>
          <Grid.Column width={9}>
            <Segment basic className="feature-card">
              <Header as='h3' className="section-header">
                <Icon name='credit card' className="feature-icon" />
                <Header.Content>
                  {t.quotaManagement.title}
                  <Header.Subheader>{t.quotaManagement.subtitle}</Header.Subheader>
                </Header.Content>
              </Header>
              
              <Transition visible={showSuccess} animation='fade' duration={500}>
                <Message
                  success
                  icon='check circle'
                  header={t.quotaManagement.success.title}
                  content={t.quotaManagement.success.message}
                  className="success-message"
                />
              </Transition>

              <Form size='large' error={!!error} className="topup-form">
                <Form.Input
                  fluid
                  icon='key'
                  iconPosition='left'
                  placeholder={t.quotaManagement.redemptionCode.placeholder}
                  name='redemptionCode'
                  value={redemptionCode}
                  onChange={(e) => {
                    setRedemptionCode(e.target.value);
                    setError('');
                  }}
                  error={!!error}
                  disabled={isSubmitting}
                  label={t.quotaManagement.redemptionCode.label}
                  className="input-field"
                />
                {error && <Message error content={error} className="error-message" />}
                
                <Button.Group fluid size='large' className="action-buttons">
                  <Button 
                    primary
                    onClick={openTopUpLink}
                    icon 
                    labelPosition='left'
                    className='hover-effect purchase-button'
                    disabled={isSubmitting}
                  >
                    <Icon name='shopping cart' />
                    {t.quotaManagement.buttons.purchase}
                  </Button>
                  <Button.Or text='or' className="or-divider" />
                  <Popup
                    trigger={
                      <Button
                        positive
                        onClick={handleTopUp}
                        icon
                        labelPosition='left'
                        className='hover-effect redeem-button'
                        loading={isSubmitting}
                      >
                        <Icon name='plus circle' />
                        {isSubmitting ? t.quotaManagement.buttons.processing : t.quotaManagement.buttons.redeem}
                      </Button>
                    }
                    content={t.quotaManagement.popup.hint}
                    position='top center'
                    open={!redemptionCode.trim() && !error}
                    className='popup-hint'
                  />
                </Button.Group>
              </Form>
            </Segment>
          </Grid.Column>

          <Grid.Column width={7}>
            <Segment basic textAlign='center' className="quota-card">
              <Header as='h3' className="section-header">
                <Icon name='chart bar' className="feature-icon" />
                <Header.Content>{t.currentQuota.title}</Header.Content>
              </Header>
              <Statistic size='huge' className="quota-display">
                <Statistic.Value>{renderQuota(userQuota)}</Statistic.Value>
                <Statistic.Label>{t.currentQuota.available}</Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
        </Grid>

        <Divider horizontal style={{ margin: '3em 0' }} className="section-divider">
        </Divider>

        <Grid columns={3} stackable centered className="features-grid">
          <Grid.Column textAlign='center' className="feature-item">
            <Icon name='shield' size='large' className="feature-icon blue-gradient" />
            <p className="feature-text">{t.features.security}</p>
          </Grid.Column>
          <Grid.Column textAlign='center' className="feature-item">
            <Icon name='sync' size='large' className="feature-icon green-gradient" />
            <p className="feature-text">{t.features.instant}</p>
          </Grid.Column>
          <Grid.Column textAlign='center' className="feature-item">
            <Icon name='clock' size='large' className="feature-icon orange-gradient" />
            <p className="feature-text">{t.features.service}</p>
          </Grid.Column>
        </Grid>
      </Segment>

      <style jsx global>{`
        .modern-card {
          background: linear-gradient(145deg, #ffffff, #f8f9fa) !important;
          border: none !important;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08) !important;
          border-radius: 16px !important;
        }

        .section-header {
          margin-bottom: 1.5em !important;
        }

        .feature-icon {
          font-size: 1.5em !important;
          margin-right: 0.5em !important;
          color: #2185d0 !important;
        }

        .gradient-icon {
          background: linear-gradient(135deg, #2185d0, #21ba45) !important;
          color: white !important;
          padding: 0.5em !important;
          box-shadow: 0 4px 12px rgba(33, 133, 208, 0.2) !important;
        }

        .input-field {
          margin-bottom: 1.5em !important;
        }

        .input-field input {
          height: 3em !important;
          border-radius: 8px !important;
          border: 2px solid #e0e1e2 !important;
          transition: all 0.3s ease !important;
        }

        .input-field input:focus {
          border-color: #2185d0 !important;
          box-shadow: 0 0 0 2px rgba(33, 133, 208, 0.2) !important;
        }

        .action-buttons {
          margin-top: 2em !important;
        }

        .hover-effect {
          transition: all 0.3s ease !important;
          border-radius: 8px !important;
          height: 3em !important;
        }

        .hover-effect:hover:not(:disabled) {
          transform: translateY(-2px) !important;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
        }

        .purchase-button {
          background: linear-gradient(135deg, #2185d0, #1678c2) !important;
        }

        .redeem-button {
          background: linear-gradient(135deg, #21ba45, #16ab39) !important;
        }

        .or-divider {
          background: #f8f9fa !important;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
        }

        .quota-card {
          background: rgba(33, 133, 208, 0.03) !important;
          border-radius: 12px !important;
          padding: 2em !important;
        }

        .quota-display {
          margin-top: 1.5em !important;
        }

        .quota-display .value {
          color: #2185d0 !important;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1) !important;
        }

        .success-message {
          animation: slideDown 0.5s ease-out !important;
          border-radius: 8px !important;
          box-shadow: 0 2px 8px rgba(33, 186, 69, 0.2) !important;
        }

        .error-message {
          border-radius: 8px !important;
          box-shadow: 0 2px 8px rgba(219, 40, 40, 0.1) !important;
        }

        .feature-item {
          padding: 2em !important;
          transition: all 0.3s ease !important;
        }

        .feature-item:hover {
          transform: translateY(-5px) !important;
        }

        .feature-text {
          margin-top: 1em !important;
          color: #666 !important;
          font-size: 1.1em !important;
        }

        .blue-gradient {
          background: linear-gradient(135deg, #2185d0, #1678c2) !important;
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent !important;
        }

        .green-gradient {
          background: linear-gradient(135deg, #21ba45, #16ab39) !important;
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent !important;
        }

        .orange-gradient {
          background: linear-gradient(135deg, #f2711c, #db2828) !important;
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent !important;
        }

        .section-divider {
          position: relative !important;
          margin: 3em 0 !important;
        }

        .divider-text {
          background: white !important;
          padding: 0 1em !important;
          color: #666 !important;
        }

        .divider-icon {
          color: #2185d0 !important;
          margin-right: 0.5em !important;
        }

        .popup-hint {
          background-color: #f8f9fa !important;
          border: none !important;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
          padding: 0.8em 1.2em !important;
          font-size: 0.95em !important;
          color: #666 !important;
          border-radius: 8px !important;
        }
        
        .popup-hint:before {
          background-color: #f8f9fa !important;
          box-shadow: 1px 1px 0 0 #f8f9fa !important;
        }

        @keyframes slideDown {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </Container>
  );
};

export default TopUp;